import React from "react";
import "./banner.css";

export const Banner = () => {
  return (
    <div className="ribbon ribbon-top-right">
      <span>Test Mode</span>
    </div>
  );
};
