import React, { useState, useEffect, useCallback } from "react";
import "./otherItems.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateExchangeSettingsDetails } from "../../redux/slices";
import { exchangeSettingsActionTypes } from "../../redux/utils";
import { useNavigate } from "react-router-dom";
import { useExchangeFetch } from "../../hooks";
import { Button, FullPageLoader } from "..";
import { createBrowserHistory } from "history";

export const ExchangeOptionOtherItems = () => {
  const dispatch = useDispatch();
  const storeSettingsState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);
  const history = createBrowserHistory();

  const {
    otherOptions,
    isExLoader,
    setChangeCurrentPageFlag,
    setPrevNextFlag,
  } = useExchangeFetch();
  const dataFrom = 0;
  const dataTo = exchangeSettingsState.paginateBy;
  const [pageArr, setPageArr] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ]);
  const [activeItem, setActiveItem] = useState();
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [from, setFrom] = useState(dataFrom);
  const [to, setTo] = useState(dataTo);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowNext, setIsShowNext] = useState(true);
  const [isShowPrev, setIsShowPrev] = useState(false);

  const changeCurrentPage = (flag) => {
    setPrevNextFlag(flag);
    if (flag === "prev") {
      setChangeCurrentPageFlag((prev) => prev + 1);
      if (currentPage === 2) {
        setCurrentPage((prev) => prev - 1);
        setIsShowPrev(false);
      } else {
        setCurrentPage((prev) => prev - 1);
        setIsShowPrev(true);
        setIsShowNext(true);
      }
    } else {
      setChangeCurrentPageFlag((prev) => prev + 1);
      if (currentPage === pageArr.length - 1) {
        setCurrentPage((prev) => prev + 1);
        setIsShowNext(false);
      } else {
        setCurrentPage((prev) => prev + 1);
        setIsShowNext(true);
        setIsShowPrev(true);
      }
    }
  };
  const buttonHandler = () => {
    setActiveItem(null);
    navigate("/ExchangeWithOtherItems");
  };

  const selectItemHandler = (item) => {
    if (activeItem === item.variant_id) {
      setIsBtnDisabled(true);
      setActiveItem(null);
    } else {
      //set
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_IMAGE,
          value: item.img_url,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_NAME,
          value: item.name,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_PRICE,
          value: item.price,
        })
      );

      setActiveItem(item.variant_id);

      item.variants.length > 0
        ? setIsBtnDisabled(false)
        : setIsBtnDisabled(true);

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_SELECTED_OTHER_VARIATION_OBJECT,
          value: item,
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      if (exchangeSettingsState.otherOptionsCount <= dataTo) {
        setPageArr([1]);
      } else {
        let quotient = parseInt(
          exchangeSettingsState.otherOptionsCount / dataTo
        );
        let remainder = parseInt(
          exchangeSettingsState.otherOptionsCount % dataTo
        );

        if (remainder > 0 && remainder <= dataTo) {
          remainder = 1;
        }

        let numberOfPages = parseInt(quotient, 10) + parseInt(remainder, 10);

        let tempPageArr = [];
        for (let i = 1; i <= numberOfPages; i++) {
          tempPageArr = [...tempPageArr, i];
        }

        setPageArr(tempPageArr);
      }
    })();
  }, []);

  useEffect(() => {
    if (pageArr.length === 1) {
      setIsShowPrev(false);
    } else {
      setIsShowPrev(true);
    }

    if (currentPage === 1) {
      setIsShowPrev(false);
    } else {
      setIsShowPrev(true);
    }
  }, []);

  const backHandler = useCallback(() => {
    window.history.back();
  }, [navigate]);

  return (
    <main className="p2 choose">
      <div className="container">
        <div className="row justify-content-center row__title">
          <Link to="" onClick={backHandler} className="btn__small_screen">
            <img src="assets/svg/chevron_left.svg" alt="back" />
          </Link>
          <div className="col-12">
            <h1 className="p2__title title">Exchange option other items</h1>
            <form className="global__form d-none">
              <label className="global__search">
                <svg className="global__search-icon">
                  <use xlinkHref="assets/svg/sprite.svg#search"></use>
                </svg>
                <input
                  className="global__search-input"
                  type="text"
                  placeholder="Search..."
                />
              </label>
              <div className="global__search-dropdown">
                <ul className="global__search-list"></ul>
              </div>
            </form>
          </div>
        </div>
        <div className="p2__content">
          {isExLoader ? (
            <FullPageLoader />
          ) : (
            <div className="row justify-content-center p2__row">
              {otherOptions.slice(from, to).map((item, index) => {
                let isVariantInStock = false;
                isVariantInStock = item.variants.map((variant) => {
                  if (variant.quantity >= 1) {
                    return true;
                  }
                  return false;
                });
                isVariantInStock = isVariantInStock.includes(true);

                return (
                  <div
                    className="col-6 col-md-4 col-lg-3 "
                    key={index}
                    onClick={() => {
                      setActiveItem(item.variant_id);
                      selectItemHandler(item);
                    }}
                  >
                    <div
                      className={`p2__card card ${
                        activeItem === item.variant_id &&
                        (isVariantInStock ||
                          exchangeSettingsState.allowOutOfStock) &&
                        "active"
                      }`}
                    >
                      <span className="card__target">
                        <svg className="card__icon">
                          <use xlinkHref="assets/svg/sprite.svg#choose"></use>
                        </svg>
                      </span>
                      <div className="card__pic">
                        <img
                          src={item.img_url}
                          alt="wear"
                          className="card__img"
                        />
                      </div>
                      <div className="card__info">
                        <h2 className="card__title">{item.name}</h2>
                        <div
                          className="p2__card-info
                              exchange__card-info"
                        >
                          <p>
                            Price:{" "}
                            <span
                              className="p2__card-price
                                      exchange__card-price"
                            >
                              {`${productDetailsState.currency}${item.price}`}
                            </span>
                          </p>
                          <div className="d-flex justify-content-center view-out-of-stock">
                            {!isVariantInStock &&
                              !exchangeSettingsState.allowOutOfStock && (
                                <span className="info__error error-items error-items-padding">
                                  Out of stock
                                </span>
                              )}
                          </div>
                          <div className="d-flex justify-content-center view-details ">
                            {" "}
                            <a
                              href={`${storeSettingsState.baseURL}/variants/${item.variant_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              view details
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="row justify-content-between ">
          <div className="col-12 col-lg-6 col-xl-4 ">
            <div className="global__pages pagination-main ">
              <Link
                to=""
                onClick={() => {
                  isShowPrev && changeCurrentPage("prev");
                }}
                className={`prevArrow ${isShowPrev ? "" : "arrowDisable"}`}
              >
                {" "}
                <img
                  className="pagination_chevron"
                  src="assets/left-arrow.png"
                  width="20"
                  alt="chevron-left"
                />
              </Link>

              <span className="pageNo">
                {`${currentPage} of ${pageArr.length}`}
              </span>

              <Link
                to=""
                onClick={() => {
                  isShowNext && changeCurrentPage("next");
                }}
                className={`nextArrow ${isShowNext ? "" : "arrowDisable"}`}
              >
                <img
                  className="pagination_chevron"
                  src="assets/right-arrow.png"
                  width="20"
                  alt="chevron-right"
                />
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xxl-4">
            <div className="row btns btn-align">
              <div className="col-6 btn__big-screen">
                <button
                  className="btn btn__back back_page"
                  onClick={backHandler}
                >
                  Back
                </button>
              </div>
              <div className="col-ltl-12 col-sm-8 col-md-6">
                <Button
                  name="Continue"
                  onClick={buttonHandler}
                  disabled={isBtnDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
