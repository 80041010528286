import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { removeInsignificantZero } from '../../Utilities';
import { useExchangeFetch } from '../../hooks';
import { useSelector, useDispatch } from 'react-redux';
import { updatePaymentSettings } from '../../redux/slices';
import { paymentSettingTypes } from '../../redux/utils';

export const ExchangeSubmitted = () => {
  const { itemToBeReturnedTags, NoVariantAvailable } = useExchangeFetch();
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [paymentSuccessHeading, setPaymentSuccessHeading] =
    useState('Payment Required');
  const [paymentSuccessDesc, setPaymentSuccessDesc] = useState(
    'Click on Pay Now to complete the required payment'
  );
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const paymentPageRef = useRef();
  const navigate = useNavigate();
  const history = createBrowserHistory();

  const dispatch = useDispatch();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);
  const paymentSettingsState = useSelector((state) => state.paymentSettings);

  useEffect(() => {
    if (paymentSettingsState.paymentRequired) {
      const fetchPaymentInfo = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/ra/refund_exchange_payment_status/${paymentSettingsState.paymentID}/`
          );

          if (response?.data?.payment_status === 'completed') {
            dispatch(
              updatePaymentSettings({
                key: paymentSettingTypes.UPDATE_PAYMENT_REQUIRED,
                value: false,
              })
            );

            setIsPaymentCompleted(true);
            setIsLoadingPayment(false);

            setPaymentSuccessDesc(
              'Congratulations! Your Exchange request has been submitted and is currently under review. If it complies with our policies, you will receive an approval mail within 48-72 hours with next action.'
            );
            setPaymentSuccessHeading('Exchange Submitted');
            clearInterval(timer);
            paymentPageRef.current.close();
          }
        } catch (error) {
          if (error?.response?.status === 500) {
            navigate('/internal-server-error');
          }
        }
      };

      const timer = setInterval(() => {
        fetchPaymentInfo();
      }, 3000);
      return () => clearInterval(timer);
    }
  }, []);

  const paymentHandler = () => {
    setPaymentSuccessDesc('Waiting for the payment confirmation');
    setPaymentSuccessHeading('Payment Initiated');
    setIsLoadingPayment(true);
    if (paymentSettingsState.paymentRequired) {
      paymentPageRef.current = window.open(
        paymentSettingsState.paymentLink,
        '_blank'
      );
    }
  };
  const buttonWithLoader = (
    <button
      type="button"
      className="btn btn__continue button"
      style={{
        marginBottom: '220px',
      }}
    >
      <div className=" spinner-border spinner-border-sm" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </button>
  );
  const payBlock = isLoadingPayment ? (
    buttonWithLoader
  ) : (
    <button
      type="button"
      className="btn btn__continue button"
      style={{
        marginBottom: '220px',
      }}
      onClick={paymentHandler}
    >
      PAY NOW
    </button>
  );

  /* if back button press it will navigate to home page */
  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        navigate('/');
      }
    });
  }, [history, navigate]);

  return (
    <main className="p23 choose">
      <div className="container">
        <div className="row justify-content-center p23__row">
          <div className="col-12 for-change-title"> </div>
          <div className="col-12 col-md-8  col-lg-4 order-1 order-lg-0">
            <div className="p23__wrapper">
              <div className="row__title">
                <h1 className="p23__title title">
                  {paymentSettingsState.paymentRequired || isPaymentCompleted
                    ? paymentSuccessHeading
                    : 'Exchange Submitted'}
                </h1>
                <p className="p23__desc description">
                  {paymentSettingsState.paymentRequired || isPaymentCompleted
                    ? paymentSuccessDesc
                    : 'Congratulations! Your exchange request is submitted.'}
                </p>
              </div>
              <p className="p23__desc d-none">
                Check your e-mail{' '}
                <a href="mailto:test@test.com" className="p23__link">
                  {productDetailsState.customerEmail}
                </a>{' '}
                In few hours to access label
              </p>
              <p className="p23__desc d-none">
                Follow the instructions as per e-mail
              </p>
              <div className="p23__rating rating d-none">
                <div className="p23__stars stars"></div>
                <span className="p23__rating_text rating_text">
                  Rate your return expereince
                </span>
              </div>
            </div>
            <div className="row btns shop-more-btn">
              <div className="col-12 col-sm-6 ">
                {paymentSettingsState.paymentRequired ? (
                  payBlock
                ) : (
                  <a
                    href={`${settingState.baseURL}/collections/all`}
                    className="btn btn__back back-page"
                    onClick={paymentHandler}
                  >
                    shop more
                  </a>
                )}
              </div>
              <div className="col-6 d-none">
                <button type="button" className="btn btn__continue button">
                  Drop now
                </button>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-4 mb-5 mb-lg-0">
            <div className="exchange__card ">
              <span className="exchange__card_status status__old">old</span>
              <div className="exchange__picture">
                <img
                  src={selectedProductDetailsState.selectedProductImage}
                  alt=""
                  className="exchange__img"
                />
              </div>
              <div className="exhange__text">
                <p className="exchange__card_desc">
                  {exchangeSettingsState.selectedProductName}
                </p>
                <div className="exchange__card_info">
                  <p
                    className={`${
                      !selectedProductDetailsState.selectedProductPrice &&
                      'd-none'
                    }`}
                  >
                    Price:{' '}
                    <span className="exchange__card_price">
                      {productDetailsState.currency}
                      {removeInsignificantZero(
                        parseFloat(
                          selectedProductDetailsState.selectedProductPrice
                        )
                      )}
                    </span>{' '}
                  </p>
                  <span className="exchange__card_separator">
                    {itemToBeReturnedTags
                      ?.substring(0, itemToBeReturnedTags?.length - 1)
                      .replace(/,/g, '/')
                      .split(' ')
                      .join(':')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-4 mb-5 mb-lg-0">
            <div className="exchange__card">
              <span className="exchange__card_status status__new">new</span>
              <div className="exchange__picture">
                <img
                  src={exchangeSettingsState.newItemImage}
                  alt=""
                  className="exchange__img"
                />
              </div>
              <div className="exhange__text">
                <p className="exchange__card_desc">
                  {exchangeSettingsState.newItemName}
                </p>
                <div className="exchange__card_info">
                  <p
                    className={`${
                      !exchangeSettingsState.newItemPrice && 'd-none'
                    }`}
                  >
                    Price:{' '}
                    <span className="exchange__card_price">
                      {productDetailsState.currency}
                      {removeInsignificantZero(
                        parseFloat(exchangeSettingsState.newItemPrice)
                      )}
                    </span>{' '}
                  </p>
                  <span
                    className={`exchange__card_separator ${
                      (!exchangeSettingsState.newItemSize ||
                        exchangeSettingsState.newItemSize === '') &&
                      'd-none'
                    }`}
                  >
                    /
                  </span>
                  <p
                    className={`${
                      (!exchangeSettingsState.newItemSize ||
                        exchangeSettingsState.newItemSize === '') &&
                      'd-none'
                    }`}
                  >
                    Size:{' '}
                    <span className="exchange__card_size">
                      {exchangeSettingsState.newItemSize}
                    </span>
                  </p>
                  <span
                    className={`exchange__card_separator ${
                      (!exchangeSettingsState.newItemColor ||
                        exchangeSettingsState.newItemColor === '') &&
                      'd-none'
                    }`}
                  >
                    /
                  </span>
                  <p
                    className={`${
                      (!exchangeSettingsState.newItemColor ||
                        exchangeSettingsState.newItemColor === '') &&
                      'd-none'
                    }`}
                  >
                    Color:{' '}
                    <span className="exchange__card_size">
                      {exchangeSettingsState.newItemColor}
                    </span>
                  </p>
                  <span
                    className={`exchange__card_separator ${
                      (NoVariantAvailable ||
                        exchangeSettingsState.newItemOtherTag ===
                          'Default Title') &&
                      'd-none'
                    }`}
                  >
                    {!exchangeSettingsState.newItemOtherTag ||
                    exchangeSettingsState.newItemOtherTag === ''
                      ? ''
                      : '/'}
                  </span>
                  <p>
                    <span
                      className={`exchange__card_size ${
                        (NoVariantAvailable ||
                          exchangeSettingsState.newItemOtherTag ===
                            'Default Title') &&
                        'd-none'
                      }`}
                    >
                      {exchangeSettingsState.newItemOtherTag}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
