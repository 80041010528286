import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { lang } from "../../en";
import {
  updateSetting,
  updateExchangeSettingsDetails,
} from "../../redux/slices";
import {
  storeSettingTypes,
  exchangeSettingsActionTypes,
} from "../../redux/utils";
import { Form } from "../";
import { useAppContext } from "../../contexts";

export const Main = () => {
  const search = useLocation().search;
  const history = createBrowserHistory();
  const current_domain = window.location.hostname;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let store_subdomain;

  if (current_domain === "localhost" || current_domain === "18.206.140.255") {
    store_subdomain =
      new URLSearchParams(search).get("store_subdomain") ||
      "devstoresaaraindia";
  } else {
    store_subdomain = current_domain.split(".")[0];
  }

  const platform = new URLSearchParams(search).get("platform") || "shopify";
  const auto_order_id = new URLSearchParams(search).get("order_id");
  const auto_cid = new URLSearchParams(search).get("cid");
  const { setLogoURL, setBaseURL, setIsAutoFill } = useAppContext();
  const storeConfigState = useSelector((state) => state.storeSettings);

  useEffect(() => {
    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_NAME,
        value: null,
      })
    );

    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_SELECTED_PRODUCT_NAME,
        value: null,
      })
    );
  });

  useEffect(() => {
    dispatch(
      updateSetting({
        key: storeSettingTypes.UPDATE_PLATFORM,
        value: platform,
      })
    );

    dispatch(
      updateSetting({
        key: storeSettingTypes.UPDATE_IS_JOHN_DOE_TEST_MODE,
        value: false,
      })
    );

    (async () => {
      try {
        if (store_subdomain) {
          dispatch(
            updateSetting({
              key: storeSettingTypes.UPDATE_SUBDOMAIN,
              value: store_subdomain,
            })
          );

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/ra/get_store_config/${store_subdomain}`
          );

          console.log(response);
          if (!response.data.error) {
            dispatch(
              updateSetting({
                key: storeSettingTypes.UPDATE_BRAND_LOGO,
                value: response?.data?.logo_url,
              })
            );

            dispatch(
              updateSetting({
                key: storeSettingTypes.UPDATE_BASE_URL,
                value: response?.data?.base_url,
              })
            );

            if (auto_order_id) {
              dispatch(
                updateSetting({
                  key: storeSettingTypes.UPDATE_AUTO_ORDER_ID,
                  value: auto_order_id?.toString().replace(/\D/g, ""),
                })
              );
            }

            if (auto_cid) {
              dispatch(
                updateSetting({
                  key: storeSettingTypes.UPDATE_AUTO_CID,
                  value: auto_cid,
                })
              );
            }

            if (auto_order_id && auto_cid) {
              setIsAutoFill(true);
            }

            dispatch(
              updateSetting({
                key: storeSettingTypes.UPDATE_STORE_UNIQUE_ID,
                value: response?.data?.store_unique_id,
              })
            );

            setLogoURL(response?.data?.logo_url);
            setBaseURL(response?.data?.base_url);

            /* Theme setting */
            if (response?.data?.theme_config?.primary_color !== undefined) {
              dispatch(
                updateSetting({
                  key: storeSettingTypes.UPDATE_PRIMARY_COLOR,
                  value: response?.data?.theme_config?.primary_color,
                })
              );
            }

            if (response?.data?.theme_config?.secondary_color !== undefined) {
              dispatch(
                updateSetting({
                  key: storeSettingTypes.UPDATE_SECONDARY_COLOR,
                  value: response?.data?.theme_config?.secondary_color,
                })
              );
            }

            dispatch(
              updateSetting({
                key: storeSettingTypes.UPDATE_CUSTOM_FONT,
                value: JSON.stringify(response?.data?.theme_config?.font_name),
              })
            );

            dispatch(
              updateSetting({
                key: storeSettingTypes.UPDATE_RESPONSE_MESSAGE,
                value: "An email has been sent to you with further instructions. Please see your spam/junk folder in case you don't see it in your inbox."
              })
            );

            dispatch(
              updateSetting({
                key: storeSettingTypes.UPDATE_RETURN_POLICY_URL,
                value: response?.data?.return_policy_url,
              })
            );
          }
        }

        dispatch(
          updateSetting({
            key: storeSettingTypes.UPDATE_IS_404,
            value: false,
          })
        );
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        } else if (error?.response?.status === 400) {
          dispatch(
            updateSetting({
              key: storeSettingTypes.UPDATE_IS_404,
              value: true,
            })
          );
          navigate("/Page-Not-Found");
        }
      }
    })();
  }, []);

  /* Setting the Test mode */

  /* if back button press it will navigate to home page */
  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        navigate("/");
      }
    });
  }, [history, navigate]);

  return (
    <main className="main">
      <div className="container">
        <div className="main__content">
          <div className="main__text">
            <div className="main__logo">
              <h1 className="main__title">{lang.homePage.heading}</h1>
              <p className="main__description">{lang.homePage.headingDesc}</p>

              {storeConfigState.returnPolicyURL && (
                <p className="privacy_policy">
                  Please read our{" "}
                  <a
                    href={storeConfigState.returnPolicyURL}
                    className="return_policy_url"
                    target="_blank"
                    rel="noreferrer"
                  >
                    return and exchange policies
                  </a>{" "}
                  before proceeding.
                </p>
              )}
              <img
                src="assets/brand-logo.svg"
                alt="logo"
                className="main__logo_img"
              />
            </div>
          </div>

          <Form />
          <div className="main__logo_2">
            <img
              src="assets/logo-2.webp"
              alt="logo-2"
              className="main__logo_img"
            />
          </div>
        </div>
      </div>
      <img src="assets/svg/big_logo.svg" alt="info" className="main__bg_logo" />
    </main>
  );
};
