import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '..';
import { lang } from '../../en';
import { useFetchItems } from '../../hooks';
import { useAppContext } from '../../contexts';
import './tooltip.css';

export const Form = () => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const { baseURL, isAutoFill } = useAppContext();
  const [isCustomError, setIsCustomError] = useState();
  const [customeErorMsg, setCustomErrorMsg] = useState('');
  const [autoOrderId, setAutoOrderID] = useState('');
  const [autoCID, setAutoCID] = useState('');

  const { isLoader, orderFetch, isError, errorMsg, formData, setFormData } =
    useFetchItems();

  const settingState = useSelector((state) => state.storeSettings);

  const formDataHandler = (e, orderId) => {
    const NumberPattern = /[0-9/]+$/;
    const isNumber = NumberPattern.test(e?.target?.value || parseInt(orderId));

    isNumber || e?.target?.value === ''
      ? setIsCustomError(false)
      : setIsCustomError(true);
    isNumber || e?.target?.value === ''
      ? setCustomErrorMsg('')
      : setCustomErrorMsg(lang.homePage.formCustomError);

    let value;
    if (orderId) {
      value = orderId.replace(/[^0-9\/]+$/, '');
    } else {
      value = e.target.value.replace(/[^0-9\/]+$/, '');
    }

    setFormData({ ...formData, orderNo: value });
  };

  const buttonHandler = (e) => {
    e.preventDefault();

    const value = e.target.value;

    orderFetch(value, e);
  };

  useEffect(() => {
    if (baseURL) {
      if (formData?.orderNo && formData?.emailPhoneZipCode) {
        setIsBtnDisabled(false);
      } else {
        setIsBtnDisabled(true);
      }
    }
  }, [formData.orderNo, formData.emailPhoneZipCode, baseURL]);

  useEffect(() => {
    if (isAutoFill) {
      formDataHandler(false, settingState.autoOrderId);
      setAutoCID(settingState.autoCID);
      setAutoOrderID(settingState.autoOrderId);
      setIsBtnDisabled(false);
      orderFetch(false, false, settingState.autoOrderId, settingState.autoCID);
    }
  }, [isAutoFill]);
  return (
    <form action="" className="main__form form">
      <h2 className="form__title">
        <span className="form__span">{lang.homePage.formHeading}</span>
      </h2>
      <label className="form__label">
        <input
          type=""
          className="form__inp form__order"
          placeholder={lang.homePage.formInputA}
          name="orderNo"
          defaultValue={autoOrderId}
          onChange={(e) => {
            formDataHandler(e);
          }}
          required
        />
        <div className="tooltip_custom">
          <img src="assets/svg/info.svg" className="form__info" alt="tooltip" />
          <span className="tooltiptext_custom">
            {lang.homePage.formCustomError}
          </span>
        </div>
      </label>
      {isCustomError && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ color: 'red', fontSize: '14px', paddingBottom: '15px' }}
        >
          {customeErorMsg}
        </div>
      )}
      <label className="form__label">
        <input
          type="text"
          className="form__inp form__auth"
          placeholder={lang.homePage.formInputB}
          name="emailPhoneZipCode"
          defaultValue={autoCID}
          onChange={(e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
          }}
          required
        />
      </label>
      <span className="errorMsg">{isError && errorMsg}</span>
      <Button
        name={
          isLoader ? (
            <div className=" spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            lang.commonText.continueBtn
          )
        }
        onClick={(e) => {
          buttonHandler(e);
        }}
        disabled={isBtnDisabled}
      />
    </form>
  );
};
