import React, { useState, useEffect } from "react";
import { Button } from "../../Components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { updateRefundSettingsDetails } from "../../redux/slices";
import { refundSettingsActionTypes } from "../../redux/utils";
import "./bank.css";

export const Bank = () => {
  const [bankAcNo, setBankAcNo] = useState();
  const [confirmBankAcNo, setConfirmBankAcNo] = useState();
  const [ifscCode, setIfscCode] = useState();
  const [beneficiaryName, setBeneficiaryName] = useState();
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);
  const refundSettingsState = useSelector((state) => state.refundSettings);

  useEffect(() => {
    if (bankAcNo && confirmBankAcNo && ifscCode && beneficiaryName) {
      if (bankAcNo === confirmBankAcNo) {
        setIsBtnDisabled(false);
        setErrorMsg("");
        setIsError(false);
      } else {
        setIsBtnDisabled(true);
        setErrorMsg("Account No and Confirm Ac No Should Match");
        setIsError(true);
      }
    } else {
      setIsBtnDisabled(true);
    }
  }, [bankAcNo, confirmBankAcNo, ifscCode, beneficiaryName]);

  const buttonHandler = async () => {
    try {
      setIsLoader(true);
      const VariantID = selectedProductDetailsState.variantID;
      const StoreUniqueID = settingState.storeUniqueID;
      const OrderInfo = productDetailsState.orderInfo;
      const customerID = OrderInfo.customer_id;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ra/submit_return_request/`,
        {
          store_name: StoreUniqueID,
          customer_id: customerID,
          variant_id: VariantID,
          refund_to: refundSettingsState.refundOption,
          account_number: bankAcNo,
          ifsc: ifscCode,
          beneficiary_name: beneficiaryName,
          is_refund: true,
          image_list: exchangeSettingsState.imageList,
          reason_id: productDetailsState.returnReasonID,
          other_reason_desc: productDetailsState.reasonDetails,
          email: productDetailsState.customerEmail,
          test_mode: productDetailsState.testMode,
          order_number: productDetailsState.orderNumber,
          order_id: productDetailsState.recentOrderID,
          line_item_id: selectedProductDetailsState.lineItemID,
          quantity: parseInt(selectedProductDetailsState.selectedQuantity),
        }
      );
      if (response?.status === 200) {
        setSuccessMsg(response?.data?.resp_msg);
        setIsLoader(false);

        dispatch(
          updateRefundSettingsDetails({
            key: refundSettingsActionTypes.UPDATE_THANKYOU_HEADING,
            value: "Request Submitted",
          })
        );

        dispatch(
          updateRefundSettingsDetails({
            key: refundSettingsActionTypes.UPDATE_THANKYOU_DESCRIPTION,
            value:
              "Your return request RAN# " +
              response.data.ran +
              " has been submitted, We will start processing it shortly. Refund will be processed after the item has been received by us.",
          })
        );

        dispatch(
          updateRefundSettingsDetails({
            key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT,
            value: refundSettingsState.refundCreditIncentive,
          })
        );

        dispatch(
          updateRefundSettingsDetails({
            key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT_DESCRIPTION,
            value: "minus any charges will be added",
          })
        );

        navigate("/ThankYou");
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        navigate("/internal-server-error");
      }
    }
  };

  return (
    <div className="bank-account__page">
      <div className="container">
        <div className="bank-account__row">
          <div className="bank-account__form">
            <h1 className="bank-account__form-title">
              Please enter your bank account details to receive refund
            </h1>
            {isError && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ color: "red", paddingBottom: "30px" }}
              >
                {errorMsg}
              </div>
            )}
            {successMsg !== "" && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ color: "", paddingBottom: "30px" }}
              >
                {successMsg}
              </div>
            )}
            <div className="bank-account__form-data">
              <input
                type="text"
                className="bank-account__form-inp form__bank-account"
                placeholder="Enter bank account number"
                onChange={(e) => setBankAcNo(e.target.value)}
                required
              />
              <input
                type="text"
                className="bank-account__form-inp form__rebank-account"
                placeholder="Re-enter bank account number"
                onChange={(e) => setConfirmBankAcNo(e.target.value)}
                required
              />
              <input
                type="text"
                className="bank-account__form-inp form__IFSC"
                placeholder="IFSC code"
                onChange={(e) => setIfscCode(e.target.value)}
                required
              />
              <input
                type="text"
                className="bank-account__form-inp form__beneficiary-name"
                placeholder="Enter beneficiary name"
                onChange={(e) => setBeneficiaryName(e.target.value)}
                required
              />
            </div>

            <Button
              name={
                isLoader ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Continue"
                )
              }
              onClick={buttonHandler}
              disabled={isBtnDisabled}
            />
            <img
              src="assets/svg/big_logo.svg"
              className="bank-account__bg-logo bg-logo__left"
              alt=""
            />
            <img
              src="assets/svg/big_logo.svg"
              className="bank-account__bg-logo bg-logo__right"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
