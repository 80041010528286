import { useEffect, useState } from "react";

export const useValidateOrder = () => {
  const [errorMsg, setErrorMsg] = useState("Invalid Order ID");
  const [isError, setIsError] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState();
  const [formData, setFormData] = useState({
    orderNo: "",
    emailPhoneZipCode: "",
  });

  useEffect(() => {
    if (!isError) {
      if (formData?.orderNo && formData?.emailPhoneZipCode) {
        setIsBtnDisabled(false);
      } else {
        setIsBtnDisabled();
      }
    } else {
      setIsBtnDisabled();
    }
  }, [isError, formData?.orderNo, formData?.emailPhoneZipCode, isBtnDisabled]);

  const orderNo = formData?.orderNo;
  return { orderNo, formData, setFormData, errorMsg, isError, isBtnDisabled };
};
