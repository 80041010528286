import React, { useState } from "react";
import { BackContinue, HeaderText } from "..";
import { useOrderContext } from "../../contexts";

export const RefundLast = () => {
  const { setIsSelected } = useOrderContext();
  const [refundLastID, setRefundLastID] = useState();
  const refundLast = [
    {
      id: 1,
      name: "Refund using store credits",
      desc: "Instant refund",
    },
    {
      id: 2,
      name: "Drop to nearby center",
      desc: "Takes 5-7 business days after approval",
    },
  ];
  return (
    <main className="p17">
      <div className="container">
        <div className="row justify-content-center row__title">
          <HeaderText text="Refund options" />
        </div>
        <div className=" justify-content-lg-between justify-content-center p17__row">
          {refundLast.map((item) => {
            return (
              <div
                className={`choose__card ${
                  refundLastID === item.id ? "active" : ""
                }`}
                key={item.id}
                onClick={() => {
                  setRefundLastID(item.id);
                  setIsSelected(true);
                }}
              >
                <h2 className="choose__card_title">{item.name} </h2>
                <p className="choose__text">{item.desc}</p>
                <label className="choose__label">
                  <input className="choose__input" type="radio" name="choose" />
                  <span className="choose__input_circle big"></span>
                </label>
              </div>
            );
          })}
        </div>
        <BackContinue navigateTo="/RefundLast" backTo="" />
      </div>
    </main>
  );
};
