import React from "react";
import { Tabs, TabPanel } from "react-tabs";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useExchangeFetchOther } from "../../hooks";
import { FullPageLoader } from "..";
import {
  removeInsignificantZero,
  spaceAfterComma,
  htmlColorPicker,
} from "../../Utilities";
import "react-tabs/style/react-tabs.css";
import "./exchangeOthers.css";

export const ExchangeOthers = () => {
  const {
    colors,
    setColorID,
    setSelectedColor,
    colorID,
    sizes,
    setSizeID,
    setSelectedSize,
    sizeID,
    productMainView,
    itemToBeReturnedTags,
    others,
    otherLabel,
    noVariantAvailable,
    setSelectedOthers,
    otherID,
    setOtherID,
    isExLoader,
    disableContinueButton,
  } = useExchangeFetchOther();

  const navigate = useNavigate();
  const productDetailsState = useSelector((state) => state.productDetails);
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);

  const buttonHandler = () => {
    return !exchangeSettingsState.isImageUpload
      ? navigate("/ExchangePayment")
      : navigate("/photo");
  };

  const backHandler = () => {
    window.history.back();
  };

  return (
    <>
      {isExLoader ? (
        <FullPageLoader />
      ) : (
        <main className="option choose">
          <div className="container ">
            <div className="option__row">
              <div className="option__wrapper">
                <div className="row__title mobile_hide">
                  <Link
                    to=""
                    onClick={backHandler}
                    className="btn__small_screen"
                  >
                    <img src="assets/svg/chevron_left.svg" alt="back" />
                  </Link>
                  <h1 className="option__title title">Exchange option</h1>
                </div>
                <div className="option__panel">
                  <Tabs>
                    <TabPanel>
                      <h2 className="option__subtitle">
                        {noVariantAvailable === true
                          ? ""
                          : "Exchange for Other variants"}
                      </h2>
                      <h2 className="option__subtitle">
                        {exchangeSettingsState.selectedProductName}
                      </h2>
                      <div
                        className={`option__colors ${
                          colors.length === 0 && "d-none"
                        }`}
                      >
                        <span className="option__name">Color</span>
                        <div className="option__choise">
                          {colors.map((item, index) => {
                            return (
                              <span
                                key={index}
                                onClick={() => {
                                  setColorID(index);
                                  setSelectedColor(item);
                                }}
                              >
                                <input
                                  type="radio"
                                  name="color"
                                  className="option__choise_radio"
                                  id={`color-${index}`}
                                  checked={index === colorID}
                                />
                                <label
                                  htmlFor={`color-${index}`}
                                  className="option__choise_label label__color"
                                  title={item}
                                >
                                  <span
                                    className="option__choise_circle"
                                    data-color="#D94036"
                                    style={{
                                      background: htmlColorPicker(item),
                                    }}
                                  >
                                    <span
                                      className="option__choise_ring"
                                      style={{
                                        border: `2px solid ${htmlColorPicker(
                                          item
                                        )}`,
                                      }}
                                    ></span>
                                  </span>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className={`option__size ${
                          (sizes?.length === 0 || sizes[0]?.length === 0) &&
                          "d-none"
                        }`}
                      >
                        <span className="option__name">Size</span>
                        <div className="option__choise">
                          {sizes.map((item, index) => {
                            return (
                              <span
                                onClick={() => {
                                  setSizeID(index);
                                  setSelectedSize(item);
                                }}
                                key={index}
                              >
                                <input
                                  type="radio"
                                  name="size"
                                  className="option__choise_radio"
                                  id={`size-${index}`}
                                  checked={index === sizeID}
                                />
                                <label
                                  htmlFor={`size-${index}`}
                                  className="option__choise_label
                                        label__size"
                                >
                                  <span className="option__choise_square">
                                    {item}
                                  </span>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className={`option__size ${
                          (others?.length === 0 || others[0]?.length === 0) &&
                          "d-none"
                        }`}
                      >
                        {<span className="option__name">{otherLabel}</span>}
                        <div className="option__choise">
                          {others.map((item, index) => {
                            return (
                              <span
                                onClick={() => {
                                  setOtherID(index);
                                  setSelectedOthers(item);
                                }}
                                key={index}
                              >
                                <input
                                  type="radio"
                                  name="other"
                                  className="option__choise_radio"
                                  id={`other-${index}`}
                                  checked={index === otherID}
                                />
                                <label
                                  htmlFor={`other-${index}`}
                                  className="option__choise_label
                                        label__size"
                                >
                                  <span className="option__choise_square">
                                    {item}
                                  </span>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className="row d-flex justify-content-start align-items-center btn-main-big "
                        style={{}}
                      >
                        <button
                          className="btn btn__back back_page btn__big_screen btn-back-big-device"
                          onClick={backHandler}
                        >
                          Back
                        </button>
                        <button
                          className={`form__btn btn-continue-big-device ${
                            disableContinueButton && "form__btn__disabled"
                          }`}
                          onClick={buttonHandler}
                          disabled={disableContinueButton}
                        >
                          Continue
                        </button>
                        <div
                          className="d-none   "
                          style={{ paddingTop: "30px" }}
                        ></div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
              <div className="option__right">
                <div className="option__screen">
                  <ul className="option__list">
                    <li className="option__list_item active">
                      <img
                        src={productMainView}
                        alt="wear1"
                        className="option__list_img"
                      />
                    </li>
                  </ul>
                  <div className="option__target">
                    <img
                      src={productMainView}
                      alt="wear1"
                      className="option__target_img"
                      width="250"
                      height="300"
                    />
                    {disableContinueButton && (
                      <span
                        className="info__error error-items"
                        style={{ padding: "10px 40px", margin: "12%" }}
                      >
                        OUT OF STOCK
                      </span>
                    )}
                  </div>
                </div>
                <div className="option__advert advert">
                  <h2 className="advert__title">Exchange Value</h2>
                  <div className="advert__item">
                    <p className="advert__price">
                      {productDetailsState.currency}
                      <span className="advert__price_value">
                        {removeInsignificantZero(
                          parseFloat(exchangeSettingsState.availableCredit)
                        )}
                      </span>
                    </p>
                    <p className="advert__size">
                      {spaceAfterComma(
                        itemToBeReturnedTags?.substring(
                          0,
                          itemToBeReturnedTags?.length - 1
                        )
                      )}
                    </p>
                  </div>
                  <p
                    className={`advert__bonus ${
                      exchangeSettingsState.bonus === 0 && "d-none"
                    }`}
                  >
                    +{productDetailsState.currency}
                    <span className="advert__bonus_value">
                      {exchangeSettingsState.bonus}
                    </span>{" "}
                    Bonus
                  </p>
                  <img
                    src="assets/sbg.webp"
                    className="advert__bg_img"
                    alt="bg"
                  />
                </div>
              </div>
              <div className="row__title mobile_show">
                <Link to="/" className="btn__small_screen">
                  <img src="assets/svg/chevron_left.svg" alt="back" />
                </Link>
                <h1 className="option__title title">Exchange option</h1>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};
