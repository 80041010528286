import { createContext, useContext, useState } from "react";

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [orderID, setOrderID] = useState();
  const [orderInfo, setOrderInfo] = useState();
  const [selectReturnItem, setSelectReturnItem] = useState("");
  const [isSelected, setIsSelected] = useState();
  const [recentOrderID, setRecentOrderID] = useState();
  const [variantID, setVariantID] = useState();
  const [incentive, setIncentive] = useState();

  return (
    <OrderContext.Provider
      value={{
        orderID,
        setOrderID,
        orderInfo,
        setOrderInfo,
        selectReturnItem,
        setSelectReturnItem,
        isSelected,
        setIsSelected,
        recentOrderID,
        setRecentOrderID,
        variantID,
        setVariantID,
        incentive,
        setIncentive,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
export const useOrderContext = () => {
  return useContext(OrderContext);
};
