import React from 'react';
import { useSelector } from 'react-redux';

export const ResellListing = () => {
  const productDetailsState = useSelector((state) => state.productDetails);

  return (
    <main class="p16 choose">
      <div class="container">
        <div class="row justify-content-center row__title">
          <div class="col-12">
            <h1 class="p16 __title title">Resell listing</h1>
            <p class="p16__desc description">
              Thank you for helping reduce carbon footprint by sharing this.
              Once someone buys it, you can handover the item to them
            </p>
          </div>
        </div>
        <div class="p16__row">
          <div class="p16__content">
            <div class="p16__left">
              <div class="p16__picture">
                <img src="assets/wear1.webp" alt="wear" class="p16__img" />
              </div>
            </div>
          </div>
          <div class="p16__content">
            <div class="p16__right info">
              <ul class="p16__list info__list">
                <li class="info__item">
                  <span class="info__property">Bought on:</span>
                  <span class="info__value">20 July, 2021</span>
                </li>
                <li class="info__item">
                  <span class="info__property">Original price:</span>
                  <span class="info__value">
                    {productDetailsState.currency}50
                  </span>
                </li>
                <li class="info__item">
                  <span class="info__property">Sale price:</span>
                  <span class="info__value">$45</span>
                </li>
                <li class="info__item">
                  <span class="info__property">Reason for resale:</span>
                  <span class="info__value">Doesn’t fit</span>
                </li>
                <li class="info__item">
                  <span class="info__property">Delivery:</span>
                  <span class="info__value">Hand delivered by Buyer</span>
                </li>
                <li class="info__item">
                  <span class="info__property">Zipcode:</span>
                  <span class="info__value">560102</span>
                </li>
              </ul>
              <p class="p16__offer description">
                Save carbon footprint by buying this item ad discounted price
              </p>
            </div>
          </div>
        </div>

        <div class="row btns">
          <div class="col-12 ">
            <div class="btns__text">
              <p class="btn__desc description">
                By sharing you will get {productDetailsState.currency}2 in form
                of store credits
              </p>
            </div>
          </div>

          <div class="col-12 col-md-4 col-lg-3 col-xl-2">
            <button type="button" class="btn btn__continue button">
              Continue
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};
