import {
  LANDING,
  HOME,
  ITEMS,
  REASON,
  PHOTO,
  RETURN_OPTIONS,
  EXCHANGE_OPTIONS,
  NEIGHBOUR,
  REFUND,
  RESELL,
  THANKYOU,
  CARRIER,
  REQUEST_SUBMITTED,
  REFUND_LAST,
  EXCHANGE_PAYMENT,
  EXCHANGE_SUBMITTED,
  PRINT_SHIPPING_LABEL,
  REFUND_OPTIONS,
  EXCHANGE_REVIEW,
  COD,
  OTHER_ITEMS,
  EXCHANGE_WITH_OTHER_ITEMS,
  E500,
  E404,
} from "./paths";
import {
  Home,
  Items,
  Reason,
  Photo,
  ReturnOptionsPage,
  ExchangeOption,
  Neighbour,
  Refund,
  Resell,
  ThankYou,
  Carrier,
  RequestSubmittedPage,
  RefundLastPage,
  ExPayment,
  ExchangeSubmittedPage,
  PrintShippingLabel,
  RefundOptionsPage,
  ExchangeReviewPage,
  Cod,
  OtherItems,
  ExchangeWithOtherItems,
  InternalServerError,
  PageNotFound,
} from "../pages/index";

const LANDING_ONE = {
  component: Home,
  path: LANDING,
};

const HOME_TWO = {
  component: Home,
  path: HOME,
};

const ITEMS_THREE = {
  component: Items,
  path: ITEMS,
};
const REASON_FOUR = {
  component: Reason,
  path: REASON,
};
const PHOTO_FIVE = {
  component: Photo,
  path: PHOTO,
};

const RETURN_PAGE_SIX = {
  component: ReturnOptionsPage,
  path: RETURN_OPTIONS,
};
const EXCHANGE_PAGE_SEVEN = {
  component: ExchangeOption,
  path: EXCHANGE_OPTIONS,
};
const NEIGHBOUR_PAGE_EIGHT = {
  component: Neighbour,
  path: NEIGHBOUR,
};

const REFUND_PAGE_NINE = {
  component: Refund,
  path: REFUND,
};

const RESELL_PAGE_TEN = {
  component: Resell,
  path: RESELL,
};

const THANKYOU_PAGE_ELEVEN = {
  component: ThankYou,
  path: THANKYOU,
};

const CARRIER_PAGE_TWELVE = {
  component: Carrier,
  path: CARRIER,
};
const REQUEST_SUBMITTED_PAGE_THIRTEEN = {
  component: RequestSubmittedPage,
  path: REQUEST_SUBMITTED,
};
const REFUND_LAST_PAGE_FOURTEEN = {
  component: RefundLastPage,
  path: REFUND_LAST,
};
const EXCHANGE_PAYMENT_PAGE_FIFTEEN = {
  component: ExPayment,
  path: EXCHANGE_PAYMENT,
};

const EXCHANGE_SUBMITTED_PAGE_SIXTEEN = {
  component: ExchangeSubmittedPage,
  path: EXCHANGE_SUBMITTED,
};

const PRINT_SHIPPING_LABEL_PAGE_SEVENTEEN = {
  component: PrintShippingLabel,
  path: PRINT_SHIPPING_LABEL,
};

const REFUND_OPTIONS_PAGE_EIGHTEEN = {
  component: RefundOptionsPage,
  path: REFUND_OPTIONS,
};

const EXCHANGE_REVIEW_PAGE_NINETEEN = {
  component: ExchangeReviewPage,
  path: EXCHANGE_REVIEW,
};

const COD_PAGE_TWENTY = {
  component: Cod,
  path: COD,
};

const OTHER_ITEMS_PAGE_TWENTY_ONE = {
  component: OtherItems,
  path: OTHER_ITEMS,
};

const EXCHANGE_WITH_OTHER_ITEMS_PAGE_TWENTY_TWO = {
  component: ExchangeWithOtherItems,
  path: EXCHANGE_WITH_OTHER_ITEMS,
};

const INTERNAL_SERVER_ERROR_PAGE_TWENTY_THREE = {
  component: InternalServerError,
  path: E500,
};

const PAGE_NOT_FOUND = {
  component: PageNotFound,
  path: E404,
};
const routes = [
  LANDING_ONE,
  HOME_TWO,
  ITEMS_THREE,
  REASON_FOUR,
  PHOTO_FIVE,
  RETURN_PAGE_SIX,
  EXCHANGE_PAGE_SEVEN,
  NEIGHBOUR_PAGE_EIGHT,
  REFUND_PAGE_NINE,
  RESELL_PAGE_TEN,
  THANKYOU_PAGE_ELEVEN,
  CARRIER_PAGE_TWELVE,
  REQUEST_SUBMITTED_PAGE_THIRTEEN,
  REFUND_LAST_PAGE_FOURTEEN,
  EXCHANGE_PAYMENT_PAGE_FIFTEEN,
  EXCHANGE_SUBMITTED_PAGE_SIXTEEN,
  PRINT_SHIPPING_LABEL_PAGE_SEVENTEEN,
  REFUND_OPTIONS_PAGE_EIGHTEEN,
  EXCHANGE_REVIEW_PAGE_NINETEEN,
  COD_PAGE_TWENTY,
  OTHER_ITEMS_PAGE_TWENTY_ONE,
  EXCHANGE_WITH_OTHER_ITEMS_PAGE_TWENTY_TWO,
  INTERNAL_SERVER_ERROR_PAGE_TWENTY_THREE,
  PAGE_NOT_FOUND,
];

export default routes;
