import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PaymentFinal = () => {
  const [payID, setPayID] = useState();
  const [showCVV, setShowCVV] = useState(false);

  const productDetailsState = useSelector((state) => state.productDetails);

  const paymentOption = [
    {
      id: 1,
      name: 'Authorize your card to send exchange immediately',
    },
    {
      id: 2,
      name: 'Send my exchange after I return',
    },
  ];
  return (
    <main className="p22 choose">
      <div className="container">
        <div className="row justify-content-center row__title">
          <div class="col-12">
            <h1 class="p22__title title">Exchange option</h1>
          </div>
        </div>
        <div className="row justify-content-center p22__row">
          <div className="col-12 col-md-8 col-lg-6 col-xl-4 order-1 order-xl-0">
            <form className="p22__wrapper ">
              <div className="p22__inner">
                <div className="bank_card">
                  <div className="bank_card__block block__number">
                    <span className="bank_card__name">Card number</span>
                    <input
                      type="text"
                      maxlength="25"
                      size="25"
                      className="bank_card__number bank_card__input"
                      inputmode="numeric"
                    />
                  </div>
                  <div className="bank_card__block block__date">
                    <span className="bank_card__name">Expire date</span>
                    <input
                      type="text"
                      maxlength="5"
                      size="5"
                      placeholder="−−/−−"
                      className="bank_card__date bank_card__input"
                      inputmode="numeric"
                    />
                  </div>
                  <div className="bank_card__block block__cvv">
                    <span className="bank_card__name">CVV</span>
                    <input
                      type={`${showCVV ? 'text' : 'password'}`}
                      maxlength="3"
                      size="3"
                      className="bank_card__cvv bank_card__input"
                      inputmode="numeric"
                    />

                    <img
                      onClick={() => {
                        setShowCVV(!showCVV);
                      }}
                      className="bank_card__icon"
                      src={`${
                        showCVV
                          ? 'assets/svg/eye.svg'
                          : 'assets/svg/crossed-eye.svg'
                      }`}
                      width="18"
                      height="18"
                      alt="hide"
                    />
                  </div>
                </div>
                <div className="p22__choose">
                  {paymentOption.map((item) => {
                    return (
                      <div
                        className={`choose__card ${
                          payID === item.id ? 'active' : ''
                        }`}
                        key={item.id}
                        onClick={() => {
                          setPayID(item.id);
                        }}
                      >
                        <h2 className="choose__card_title">{item.name}</h2>
                        <label className="choose__label">
                          <input
                            className="choose__input"
                            type="radio"
                            name="choose"
                          />
                          <span className="choose__input_circle"></span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Link to="/ExchangeSubmitted">
                <button type="button" className="btn btn__continue button">
                  continue
                </button>
              </Link>
            </form>
          </div>
          <div className="col-6 col-lg-4 mb-5 mb-lg-0">
            <div className="exchange__card">
              <span className="exchange__card_status status__new">new</span>
              <div className="exchange__picture">
                <img src="assets/wear2.webp" alt="" className="exchange__img" />
              </div>
              <div className="exhange__text">
                <p className="exchange__card_desc">
                  classNameic fit plaid oxford T&#8209;shirt
                </p>
                <div className="exchange__card_info">
                  <p>
                    Price:{' '}
                    <span className="exchange__card_price">
                      {productDetailsState.currency}55
                    </span>{' '}
                  </p>
                  <span className="exchange__card_separator">/</span>
                  <p>
                    Size: <span className="exchange__card_size">L</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-4 mb-5 mb-lg-0">
            <div className="exchange__card ">
              <span className="exchange__card_status status__old">old</span>
              <div className="exchange__picture">
                <img src="assets/wear1.webp" alt="" className="exchange__img" />
              </div>
              <div className="exhange__text">
                <p className="exchange__card_desc">
                  classNameic fit plaid oxford T&#8209;shirt
                </p>
                <div className="exchange__card_info">
                  <p>
                    Price:{' '}
                    <span className="exchange__card_price">
                      {productDetailsState.currency}55
                    </span>{' '}
                  </p>
                  <span className="exchange__card_separator">/</span>
                  <p>
                    Size: <span className="exchange__card_size">L</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
