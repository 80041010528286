import { createSlice } from '@reduxjs/toolkit';
import { paymentSettingTypes } from '../utils';

const paymentSettingsSlice = createSlice({
  name: 'paymentSettings',

  initialState: {
    paymentRequired: false,
    paymentLink: null,
    paymentID: null,
  },

  reducers: {
    updatePaymentSettings: (state, action) => {
      const { key, value } = action.payload;

      switch (key) {
        case paymentSettingTypes.UPDATE_PAYMENT_REQUIRED:
          state.paymentRequired = value;
          break;
        case paymentSettingTypes.UPDATE_PAYMENT_LINK:
          state.paymentLink = value;
          break;
        case paymentSettingTypes.UPDATE_PAYMENT_ID:
          state.paymentID = value;
          break;
        default:
      }
    },
  },
});

// Export the slice
export { paymentSettingsSlice };

// Export the actions
export const { updatePaymentSettings } = paymentSettingsSlice.actions;

// Export the reducer
export default paymentSettingsSlice.reducer;
