import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateExchangeSettingsDetails } from "../redux/slices";
import { exchangeSettingsActionTypes } from "../redux/utils";
import { usePathNameContext } from "../contexts";

export const useExchangeFetchOther = () => {
  const [colorID, setColorID] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedOthers, setSelectedOthers] = useState("");
  const [variantInStock, setVariantInStock] = useState(0);
  const [sizeID, setSizeID] = useState(0);
  const [otherID, setOtherID] = useState(0);
  const [productMainView, setProductMainView] = useState();
  const [productSideView, setProductSideView] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [variations, setVariations] = useState([]);
  const [itemToBeReturnedTags, setItemToBeReturnedTags] = useState();
  const [isExLoader, setIsExLoader] = useState(false);
  const [otherOptions, setOtherOptions] = useState([]);
  const [others, setOthers] = useState([]);
  const [otherLabel, setOtherLabel] = useState("");
  const [noVariantAvailable, setNoVariantAvailable] = useState(false);
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const { pathURL } = usePathNameContext();
  const [variantQty, setVariantQty] = useState([]);
  const [isOutOfStock, setIsOutOfStock] = useState(false);

  const dispatch = useDispatch();
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);

  const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const response = exchangeSettingsState.selectedOtherVariationObject;

  const response_exchange = exchangeSettingsState.orderExchangeInfo;

  useEffect(() => {
    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_EXCHANGE_VARIANT_ID,
        value: response?.variants[0]?.variant_id,
      })
    );

    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_ALLOW_OUT_OF_STOCK,
        value: response_exchange.data.allow_out_of_stock,
      })
    );

    if (
      response?.options.length === 1 &&
      response?.options[0]?.name === "Title" &&
      response?.options[0]?.values.length === 1 &&
      response.options[0]?.values[0] === "Default Title"
    ) {
      setNoVariantAvailable(true);
    }

    let variation_color = [],
      variation_size = [],
      variation_other_tag = [],
      variation_product_main_view = [],
      variation_product_side_view = [];

    response?.variants.map((variant) => {
      variation_color = [...variation_color, variant.color].filter(distinct);
      variation_size = [...variation_size, variant.size].filter(distinct);
      variation_other_tag = [...variation_other_tag, variant.other_tag].filter(
        distinct
      );
      variation_product_main_view = [
        ...variation_product_main_view,
        variant.img_url,
      ];
      variation_product_side_view = [
        ...variation_product_main_view,
        variant.img_url,
      ];

      // console.log(response)

      setSizes(variation_size.filter((el) => el != null && el !== ""));
      setColors(variation_color.filter((el) => el != null && el !== ""));
      setOthers(variation_other_tag.filter((el) => el != null && el !== ""));
      setProductMainView(variation_product_main_view);
      setProductSideView(variation_product_side_view);
      setVariations(response?.variants);

      response?.options.map((item) => {
        if (
          item.name !== "Size" &&
          item.name !== "Color" &&
          item.name !== "Title"
        ) {
          setOtherLabel(item.name);
        }
      });
    });

    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_NAME,
        value: response?.name,
      })
    );
    setSelectedColor(response?.variants[0]?.color);
    setSelectedSize(response?.variants[0]?.size);
    setSelectedOthers(response?.variants[0]?.other_tag);

    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_SELECTED_COLOR,
        value: response?.variants[0]?.color,
      })
    );

    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_SELECTED_SIZE,
        value: response?.variants[0]?.size,
      })
    );

    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_SELECTED_OTHER,
        value: response?.variants[0]?.other_tag,
      })
    );

    setProductMainView(response?.variants[0]?.images[0]);

    setProductSideView(response?.variants[0]?.images[0]);
  }, []);

  useEffect(() => {
    if (pathURL.toLowerCase() === "/exchangewithotheritems") {
      const filteredItem = variations.find(
        (item) =>
          item.size === selectedSize &&
          item.color.toLowerCase() === selectedColor.toLowerCase()
      );

      const updateSelectedVariantItemData = (item) => {
        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_PRICE,
            value: item.price,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_IMAGE,
            value: item.img_url,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_SIZE,
            value: item.size,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_COLOR,
            value: item.color,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_OTHER_TAG,
            value: item.other_tag,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_NAME,
            value: response.name,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_EXCHANGE_VARIANT_ID,
            value: item.variant_id,
          })
        );

        setProductSideView(item.images);
        setProductMainView(item.img_url);
      };

      // If Selected Size, Color and Other Tag variant exists
      // If not exist, It automatically re-rendered with size and color that exists
      if (filteredItem) {
        updateSelectedVariantItemData(filteredItem);

        // setDisableContinueButton
        if (
          filteredItem.quantity === 0 &&
          !exchangeSettingsState.allowOutOfStock
        ) {
          setDisableContinueButton(true);
        } else setDisableContinueButton(false);
      }

      /* setting up size and other variants when user click on the variants */
      let variation_size = [],
        variation_other_tag = [],
        isInStock = false;

      variations.map((item) => {
        if (item.color.toLowerCase() === selectedColor.toLowerCase()) {
          return (
            (isInStock = true),
            (variation_size = [...variation_size, item.size].filter(distinct))
          );
        }
      });

      variations.map((item) => {
        if (
          item.color.toLowerCase() === selectedColor.toLowerCase() &&
          item.size === selectedSize
        ) {
          return (
            (isInStock = true),
            (variation_other_tag = [
              ...variation_other_tag,
              item.other_tag,
            ].filter(distinct))
          );
        }
      });

      if (variation_other_tag.length === 0) {
        setSelectedSize((prev) => {
          return (isInStock = false), (prev = variation_size[0]);
        });

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_SIZE,
            value: variation_size[0],
          })
        );

        setSizeID((prev) => {
          return (prev = 0);
        });

        setOtherID(0);

        setSelectedOthers((prev) => {
          return (prev = variation_other_tag[0]);
        });
      }

      if (isInStock) {
        setIsOutOfStock(false);
      } else {
        setIsOutOfStock(true);
      }

      if (!selectedOthers) {
        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_OTHER_TAG,
            value: variation_other_tag[0],
          })
        );
      } else {
        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_OTHER_TAG,
            value: selectedOthers,
          })
        );
      }

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_COLOR,
          value: selectedColor,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_SIZE,
          value: selectedSize,
        })
      );

      setSizes(variation_size);
      setOthers(variation_other_tag);
    }
  }, [selectedColor, selectedSize, variations, selectedOthers, pathURL]);

  return {
    colors,
    setColorID,
    setSelectedColor,
    colorID,
    sizes,
    setSizeID,
    setSelectedSize,
    sizeID,
    productSideView,
    productMainView,
    itemToBeReturnedTags,
    isExLoader,
    setIsExLoader,
    otherOptions,
    others,
    setOthers,
    otherLabel,
    noVariantAvailable,
    selectedOthers,
    setSelectedOthers,
    otherID,
    setOtherID,
    setProductMainView,
    setProductSideView,
    setSizes,
    setColors,
    variantInStock,
    setVariantInStock,
    variantQty,
    setVariantQty,
    isOutOfStock,
    disableContinueButton,
  };
};
