import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BackContinue, HeaderText } from '..';
import { useOrderContext } from '../../contexts';

export const ReturnCarrier = () => {
  const { setIsSelected } = useOrderContext();
  const [carrierID, setCarrierID] = useState();

  const productDetailsState = useSelector((state) => state.productDetails);

  const carrierOptions = [
    {
      id: '1',
      name: 'Request a Shipping label',
      currency: productDetailsState.currency,
      amount: '2',
    },
    {
      id: '2',
      name: 'Drop to nearby center',
      currency: productDetailsState.currency,
      amount: '2',
    },
    {
      id: '3',
      name: 'Request a Home Pickup',
      currency: productDetailsState.currency,
      amount: '5',
    },
  ];
  return (
    <main class="p14 p6">
      <div class="container">
        <div class="row justify-content-center row__title">
          <HeaderText text="Return options" />
        </div>
        <div class="justify-content-lg-between justify-content-center p14__row p6__row">
          {carrierOptions.map((item) => {
            return (
              <div
                class={`choose__card ${carrierID === item.id ? 'active' : ''}`}
                key={item.id}
                onClick={() => {
                  setCarrierID(item.id);
                  setIsSelected(true);
                }}
              >
                <h2 class="choose__card_title">{item.name}</h2>
                <p class="choose__text">
                  Cost {item.currency} {item.amount}
                </p>
                <label class="p6__label">
                  <input class="choose__input" type="radio" name="choose" />
                  <span class="choose__input_circle big"></span>
                </label>
              </div>
            );
          })}
        </div>

        <BackContinue navigateTo="/RequestSubmitted" backTo="/Return" />
      </div>
    </main>
  );
};
