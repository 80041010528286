import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BackContinue } from '..';
import { useOrderContext } from '../../contexts';

export const SellToNeighbour = () => {
  const { setIsSelected } = useOrderContext();
  const productDetailsState = useSelector((state) => state.productDetails);

  useEffect(() => {
    setIsSelected(true);
  }, [setIsSelected]);

  return (
    <main className="p9 choose">
      <div className="container">
        <div className="row justify-content-center row__title">
          <div className="col-12">
            <h1 className="p9 __title title">
              Awesome! You are our climate warrior
            </h1>
            <p className="p9__desc description">
              We will add 3 days to your return period
            </p>
          </div>
        </div>
        <div className="row justify-content-center choose__row p9__row">
          <div className="col-12  col-md-7 col-lg-4 ">
            <div className="p9__card">
              <div className="p9__circle circle">
                <p className="circle__text">
                  10<span className="symbol">$</span>
                </p>
              </div>
              <p className="p9__card-text">
                Get store credits when someone buys the product
              </p>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-4  ">
            <div className="p9__card">
              <div className="p9__circle circle">
                <p className="circle__text">
                  90
                  <span className="symbol">{productDetailsState.currency}</span>
                </p>
              </div>
              <p className="p9__card-text">Get max value for your product</p>
            </div>
          </div>
          <div className="col-12  col-md-7 col-lg-4  ">
            <div className="p9__card">
              <div className="p9__circle circle">
                <img src="assets/svg/reduse.svg" alt="reduse" />
              </div>
              <p className="p9__card-text">
                Save landfils by promoting product reuse
              </p>
            </div>
          </div>
        </div>

        <div className="row btns">
          <div className="col-12">
            <p className="btn__desc description">
              By clicking continue you accept that this product is usable
            </p>
          </div>
          <BackContinue
            navigateTo="/Resell"
            backTo="/return"
            nextName="Resell"
          />
        </div>
      </div>
    </main>
  );
};
