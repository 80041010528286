import { createSlice } from "@reduxjs/toolkit";
import { exchangeSettingsActionTypes } from "../utils";

const exchangeSettingsSlice = createSlice({
  name: "exchangeSettings",

  initialState: {
    isImageUpload: null,
    isMultipleImagesUpload: null,
    isSingleImageUpload: null,
    allowExchange: null,
    allowReturn: null,
    finalExchange: null,
    isEnableRefund: null,
    finalIncentive: null,
    navigateBack: null,
    navigateForward: null,
    newItemSize: null,
    newItemOtherTag: null,
    newItemColor: null,
    newItemName: null,
    selectedProductName: null,
    allowOutOfStock: null,
    orderExchangeInfo: null,
    availableCredit: null,
    bonus: null,
    newItemPrice: null,
    newItemImage: null,
    exchangeVariantID: null,
    selectedColor: null,
    selectedSize: null,
    selectedOther: null,
    nextURL: null,
    prevURL: null,
    otherOptionsCount: null,
    paginateBy: null,
    variantLabels: null,
    selectedItemColor: null,
    selectedItemOtherTag: null,
    selectedItemSize: null,
    selectedOtherVariationObject: null,
    imageList: null,
    isCustomItem: null,
  },

  reducers: {
    updateExchangeSettingsDetails: (state, action) => {
      const { key, value } = action.payload;

      switch (key) {
        case exchangeSettingsActionTypes.UPDATE_IS_IMAGE_UPLOAD:
          state.isImageUpload = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_IS_MULTIPLE_IMAGES_UPLOAD:
          state.isMultipleImagesUpload = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_IS_SINGLE_IMAGE_UPLOAD:
          state.isSingleImageUpload = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_ALLOW_EXCHANGE:
          state.allowExchange = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_ALLOW_RETURN:
          state.allowReturn = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_FINAL_EXCHANGE:
          state.finalExchange = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_IS_ENABLE_REFUND:
          state.isEnableRefund = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_FINAL_INCENTIVE:
          state.finalIncentive = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NAVIGATE_BACK:
          state.navigateBack = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NAVIGATE_FORWARD:
          state.navigateForward = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NEW_ITEM_SIZE:
          state.newItemSize = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NEW_ITEM_OTHER_TAG:
          state.newItemOtherTag = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NEW_ITEM_COLOR:
          state.newItemColor = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NEW_ITEM_NAME:
          state.newItemName = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_SELECTED_PRODUCT_NAME:
          state.selectedProductName = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_ALLOW_OUT_OF_STOCK:
          state.allowOutOfStock = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_ORDER_EXCHANGE_INFO:
          state.orderExchangeInfo = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_AVAILABLE_CREDITS:
          state.availableCredit = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_BONUS:
          state.bonus = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NEW_ITEM_PRICE:
          state.newItemPrice = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NEW_ITEM_IMAGE:
          state.newItemImage = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_EXCHANGE_VARIANT_ID:
          state.exchangeVariantID = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_SELECTED_COLOR:
          state.selectedColor = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_SELECTED_SIZE:
          state.selectedSize = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_SELECTED_OTHER:
          state.selectedOther = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_NEXT_URL:
          state.nextURL = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_PREV_URL:
          state.prevURL = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_OTHER_OPTIONS_COUNT:
          state.otherOptionsCount = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_PAGINATED_BY:
          state.paginateBy = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_VARIANTS_LABELS:
          state.variantLabels = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_COLOR:
          state.selectedItemColor = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_OTHER_TAG:
          state.selectedItemOtherTag = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_SIZE:
          state.selectedItemSize = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_SELECTED_OTHER_VARIATION_OBJECT:
          state.selectedOtherVariationObject = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_IMAGE_LIST:
          state.imageList = value;
          break;
        case exchangeSettingsActionTypes.UPDATE_IS_CUSTOM_ITEM:
          state.isCustomItem = value;
          break;
        default:
      }
    },
  },
});

// Export the slice
export { exchangeSettingsSlice };

// Export the actions
export const { updateExchangeSettingsDetails } = exchangeSettingsSlice.actions;

// Export the reducer
export default exchangeSettingsSlice.reducer;
