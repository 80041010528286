import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { removeInsignificantZero } from '../../Utilities';
import './refundThankYou.css';

export const RefundThankYou = () => {
  const history = createBrowserHistory();
  const navigate = useNavigate();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const refundSettingsState = useSelector((state) => state.refundSettings);

  /* browser back button handler */
  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        navigate('/');
      }
    });
  }, [history, navigate]);

  return (
    <main className="p13 choose">
      <div className="container">
        <div className="p13__row">
          <div className="p13__content">
            <div className="p13__left">
              <h1 className="p13__title" style={{ fontSize: '35px' }}>
                {refundSettingsState.thankYouHeading}
              </h1>
              <div className="mobile_show">
                <div className="p13__content  ">
                  <div className="p13__right">
                    <h2 className="p13__right_title">
                      {refundSettingsState.thankYouCardHeading}
                    </h2>
                    <div className="p13__right_circle">
                      <span className="p13__right_price">
                        {productDetailsState.currency}
                        {removeInsignificantZero(
                          parseFloat(refundSettingsState.keepTheItemAmount)
                        )}
                      </span>
                      <span
                        className="p13__right_action"
                        style={{ fontSize: '12px' }}
                      >
                        {refundSettingsState.keepTheItemAmountDesc}
                      </span>
                    </div>
                    <img
                      src="assets/sbg.webp"
                      alt=""
                      className="p13__right_img"
                    />
                  </div>
                </div>
                <p
                  className="p13__desc"
                  style={{ textAlign: 'justify', paddingTop: '15px' }}
                >
                  {refundSettingsState.thankYouDesc}
                </p>
                {settingState.responseMessage?.length > 0 && (
                  <p
                    className="p13__desc"
                    style={{ textAlign: 'justify', paddingTop: '0px' }}
                  >
                    {settingState.responseMessage?.length > 0
                      ? settingState.responseMessage
                      : refundSettingsState.thankYouDesc}
                  </p>
                )}
              </div>

              <div className="p13__rating rating d-none">
                <div className="p13__stars stars"></div>
              </div>
              <p
                className="p13__desc mobile_hide"
                style={{ textAlign: 'justify', paddingTop: '15px' }}
              >
                {refundSettingsState.thankYouDesc}
              </p>
              {settingState.responseMessage?.length > 0 && (
                <p
                  className="p13__desc mobile_hide"
                  style={{ textAlign: 'justify', paddingTop: '0px' }}
                >
                  {settingState.responseMessage?.length > 0
                    ? settingState.responseMessage
                    : refundSettingsState.thankYouDesc}
                </p>
              )}

              <div className="row btns shop-more-btn">
                <div className="col-12 col-sm-6 ">
                  <a
                    href={`${settingState.baseURL}/collections/all`}
                    className="btn btn__back back-page"
                  >
                    shop more
                  </a>
                </div>
                <div className="col-6 d-none">
                  <button type="button" className="btn btn__continue button">
                    Drop now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="p13__content hidden">
            <div className="p13__right">
              <h2 className="p13__right_title">
                {refundSettingsState.thankYouCardHeading}
              </h2>
              <div className="p13__right_circle">
                <span className="p13__right_price">
                  {productDetailsState.currency}
                  {removeInsignificantZero(
                    parseFloat(refundSettingsState.keepTheItemAmount)
                  )}
                </span>
                <span className="p13__right_action">
                  {refundSettingsState.keepTheItemAmountDesc}
                </span>
              </div>
              <img src="assets/sbg.webp" alt="" className="p13__right_img" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
