import React, { useState, useEffect } from 'react';
import { Button } from '..';
import { useNavigate } from 'react-router-dom';
import { useOrderContext } from '../../contexts';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateExchangeSettingsDetails,
  updateRefundSettingsDetails,
} from '../../redux/slices';
import {
  exchangeSettingsActionTypes,
  refundSettingsActionTypes,
} from '../../redux/utils';
import { removeInsignificantZero } from '../../Utilities';
import './getRefund.css';

export const GetRefund = () => {
  const {
    setIsSelected,
    setRecentOrderID,
    setVariantID,
    setIncentive,
    incentive,
  } = useOrderContext();
  const [refundReturnID, setRefundReturnID] = useState();
  const [isIncentive, setIsIncentive] = useState('true');
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [originalAmount, setOriginalAmount] = useState();
  const [storeCreditAmount, setStoreCreditAmount] = useState();

  const dispatch = useDispatch();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);
  const refundSettingsState = useSelector((state) => state.refundSettings);
  const storeConfigState = useSelector((state) => state.storeSettings);

  /* Setting Incentive */
  useEffect(() => {
    const response = refundSettingsState.incentive;

    productDetailsState.enableIncentive
      ? setIsIncentive('true')
      : setIncentive('false');

    setIncentive(response);
  }, [
    productDetailsState.enableIncentive,
    refundSettingsState.incentive,
    setIncentive,
  ]);

  /* Refund and Return Options */
  const RefundReturnOption = [
    {
      /*First option is disabled */
      id: '1',
      name: 'Keep the item, shop now',
      desc: 'Get -- instant store credit',
      isEnable: false,
      isShow: false,
    },
    {
      id: '2',
      name: 'Keep the item, shop later',
      desc: `${productDetailsState.currency}${incentive} store credit`,
      isEnable: productDetailsState.enableIncentive,
      isShow: productDetailsState.enableIncentive,
    },
    {
      id: '3',
      name: 'Get a refund',
      desc: 'May take up to 5-7 days from the receipt of the item',
      isEnable: exchangeSettingsState.isEnableRefund,
      isShow: productDetailsState.isShowRefund,
    },
  ];

  const buttonHandler = () => {
    (async () => {
      if (refundReturnID === '2') {
        try {
          setIsLoader(true);

          const RecentOrderID = productDetailsState.recentOrderID;
          const VariantID = selectedProductDetailsState.variantID;
          setRecentOrderID(RecentOrderID);
          setVariantID(VariantID);

          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/accept-incentive/`,
            {
              order_id: RecentOrderID,
              shop: settingState.storeUniqueID,
              variant_id: VariantID,
              test_mode: productDetailsState.testMode,
              line_item_id: selectedProductDetailsState.lineItemID,
              quantity: parseInt(selectedProductDetailsState.selectedQuantity),
            }
          );

          if (response?.status === 200) {
            setIsLoader(false);

            if (refundReturnID === '1') {
            } else if (refundReturnID === '2') {
              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_HEADING,
                  value: 'A Big Thank You!',
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_DESCRIPTION,
                  value:
                    'By keeping the item, you have not just helped us but also contributed to the environment by not adding further carbon footprints associated with product shipping. Enjoy your store credits for the next purchase!',
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_CARD_HEADING,
                  value: 'As Store Credit',
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT,
                  value: incentive,
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT_DESCRIPTION,
                  value: 'Added',
                })
              );

              navigate('/ThankYou');
            } else if (refundReturnID === '3') {
              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_HEADING,
                  value: 'Thank You!',
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_DESCRIPTION,
                  value:
                    'By keeping the item, you have not just helped us but also saved the environment. Enjoy your store credits for next purchase',
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_CARD_HEADING,
                  value: 'As Store Credit',
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT,
                  value: incentive,
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT_DESCRIPTION,
                  value: 'minus any charges will be added',
                })
              );

              navigate('/RefundOptions');
            }
          }
        } catch (error) {
          if (error?.response?.status === 500) {
            navigate('/internal-server-error');
          }
        }
      } else if (refundReturnID === '3') {
        try {
          setIsLoader(true);

          const RecentOrderID = productDetailsState.recentOrderID;
          const VariantID = selectedProductDetailsState.variantID;
          const StoreUniqueID = settingState.storeUniqueID;

          setRecentOrderID(RecentOrderID);
          setVariantID(VariantID);

          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/fetch-refund-credit/`,
            {
              order_id: RecentOrderID.toString(),
              shop: StoreUniqueID.toString(),
              variant_id: VariantID,
              order_number: productDetailsState.orderNumber,
              email: productDetailsState.customerEmail,
              test_mode: productDetailsState.testMode,
              line_item_id: selectedProductDetailsState.lineItemID,
              quantity: parseInt(selectedProductDetailsState.selectedQuantity),
            }
          );

          /* storing the original payment  and store credit amount getting from API */
          setOriginalAmount(response?.data?.refund_credit);

          dispatch(
            updateRefundSettingsDetails({
              key: refundSettingsActionTypes.UPDATE_REFUND_ORIGINAL_INCENTIVE,
              value: removeInsignificantZero(
                parseFloat(response?.data?.refund_credit)
              ),
            })
          );

          setStoreCreditAmount(response?.data?.refund_credit);

          dispatch(
            updateRefundSettingsDetails({
              key: refundSettingsActionTypes.UPDATE_REFUND_CREDIT_INCENTIVE,
              value: removeInsignificantZero(
                parseFloat(response?.data?.refund_credit)
              ),
            })
          );

          setIsLoader(false);
          navigate('/RefundOptions');
          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_NAVIGATE_BACK,
              value: '/refund',
            })
          );
        } catch (error) {
          if (error?.response?.status === 500) {
            navigate('/internal-server-error');
          }
        }
      }
    })();
  };

  /*Navigate to Previous Page */
  const backHandler = () => {
    window.history.back();
  };

  return (
    <div className="p12 choose">
      <div className="container">
        <div className="row justify-content-center row__title">
          <Link to="" onClick={backHandler} className="btn__small_screen">
            <img src="assets/svg/chevron_left.svg" alt="back" />
          </Link>
          <div className="col-12">
            <h1 className="p12__title title">Return options</h1>
          </div>
        </div>
        <div className="d-flex justify-content-center p12__row">
          {RefundReturnOption.map((item) => {
            return (
              <div
                className={` ${
                  !item.isShow && 'd-none'
                } d-flex border align-items-center  choose__card cursor-pointer ${
                  refundReturnID === item.id && item.isEnable ? 'active' : ''
                } `}
                key={item.id}
                onClick={() => {
                  setRefundReturnID(item.id);
                  setIsSelected(true);
                  setIsBtnDisabled(!item.isEnable ? true : false);
                }}
              >
                <div className="choose__card_text d-flex row justify-content-center align-items-center">
                  <h2 className="choose__card_title">{item.name}</h2>

                  <p className="choose__text ">
                    {' '}
                    <img
                      src="assets/svg/checkbox_light_blue.svg"
                      alt="check"
                      className="p12__icon"
                    />{' '}
                    {item.desc}
                  </p>
                  {!item.isEnable && (
                    <span className="row  justify-content-center info__error ">
                      {storeConfigState.returnPolicyURL ? (
                        <a
                          className="info__error_policy"
                          href={storeConfigState.returnPolicyURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Not allowed as per store policies.
                        </a>
                      ) : (
                        'Not allowed as per store policies.'
                      )}
                    </span>
                  )}
                </div>
                <label className="p12__label">
                  <input className="choose__input" type="radio" name="choose" />
                  <span className="choose__input_circle big "></span>
                </label>
              </div>
            );
          })}
        </div>
        <div className="row btns">
          <div className="col-6 col-md-4 col-lg-3 col-xl-2 btn__big_screen">
            <button className="btn btn__back back_page" onClick={backHandler}>
              Back
            </button>
          </div>

          <div className="col-12 col-md-4 col-lg-3 col-xl-2">
            <Button
              name={
                isLoader ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  'Continue'
                )
              }
              onClick={buttonHandler}
              disabled={isBtnDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
