import React from 'react';
import { Link } from 'react-router-dom';
export const RequestSubmitted = () => {
  return (
    <main class="p18 choose">
      <div class="container">
        <div class="p18__row">
          <div class="p18__content">
            <div class="p18__left">
              <h1 class="p18__title title">Request Submitted</h1>
              <div class="p18__rating rating">
                <div class="p18__stars stars"></div>
                <span class="p18__rating_text rating-text">
                  Rate your return expereince
                </span>
              </div>
              <p class="p18__desc">
                Your shipping label request has been submitted. It shall arrive
                in your e-mail
                <a href="mailto:absd@saara.io" class="p18__link">
                  absd@saara.io
                </a>
                shortly.
              </p>
              <p class="p18__desc">
                Please print it and ship the item back. Once items is received,
                refund will be issued to “Store credit/Bank”
              </p>
            </div>
          </div>

          <div class="p18__content content__right">
            <div class="p18__right">
              <div class="p18__payment">
                <span class="p18__payment_circle"></span>
                <ul class="p18__payment_list payment-list">
                  <li class="p18__payment_item payment_item">
                    <span class="p18__payment_property payment_property">
                      Item value:
                    </span>
                    <span class="p18__payment_value payment_value">500/-</span>
                  </li>
                  <li class="p18__payment_item payment_item">
                    <span class="p18__payment_property payment_property">
                      Pickup/Label:
                    </span>
                    <span class="p18__payment_value payment_value">100/-</span>
                  </li>
                  <li class="p18__payment_item payment_item">
                    <span class="p18__payment_property payment_property">
                      Other free:
                    </span>
                    <span class="p18__payment_value payment_value">0/-</span>
                  </li>
                  <li class="p18__payment-line"></li>
                  <li class="p18__payment_item payment_item">
                    <span class="p18__payment_property payment_property">
                      Total Refund:
                    </span>
                    <span class="p18__payment_value payment_value">400/-</span>
                  </li>
                </ul>
              </div>
              <div class="p18__buttons">
                <button class="p18__payment_btn">Drop now</button>
                <Link to="/RefundLast">
                  <button
                    type="button"
                    class="button btn__continue
                  btn"
                  >
                    Continue shopping
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
