export const LANDING = "/";
export const HOME = "/Home";
export const ITEMS = "/Items";
export const REASON = "/Reason";
export const PHOTO = "/Photo";
export const RETURN_OPTIONS = "/Return"; //Change to resolution
export const EXCHANGE_OPTIONS = "/Exchange";
export const NEIGHBOUR = "/Neighbour";
export const REFUND = "/Refund";
export const RESELL = "/Resell";
export const THANKYOU = "/ThankYou";
export const CARRIER = "/Carrier";
export const REQUEST_SUBMITTED = "/RequestSubmitted";
export const REFUND_LAST = "/RefundLast";
export const EXCHANGE_PAYMENT = "/ExchangePayment";
export const EXCHANGE_SUBMITTED = "/ExchangeSubmitted";
export const PRINT_SHIPPING_LABEL = "/PrintShippingLabel";
export const EXCHANGE_OPTIONS_NEXT = "/ExchangeOptions";
export const REFUND_OPTIONS = "/RefundOptions";
export const EXCHANGE_REVIEW = "/ExchangeReview";
export const COD = "/Cod";
export const OTHER_ITEMS = "/OtherItems";
export const EXCHANGE_WITH_OTHER_ITEMS = "/ExchangeWithOtherItems";
export const E500 = "/internal-server-error";
export const E404 = "/Page-Not-Found";
