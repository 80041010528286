import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { useSelector, useDispatch } from 'react-redux';
import { updateExchangeSettingsDetails } from '../redux/slices';
import { exchangeSettingsActionTypes } from '../redux/utils';
import { usePathNameContext } from '../contexts';

export const useExchangeFetch = () => {
  const [colorID, setColorID] = useState(0);
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedOthers, setSelectedOthers] = useState('');
  const [sizeID, setSizeID] = useState(0);
  const [otherID, setOtherID] = useState(0);
  const [productMainView, setProductMainView] = useState();
  const [productSideView, setProductSideView] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [variations, setVariations] = useState([]);
  const [itemToBeReturnedTags, setItemToBeReturnedTags] = useState('');
  const [isExLoader, setIsExLoader] = useState(false);
  const [otherOptions, setOtherOptions] = useState([]);
  const [others, setOthers] = useState([]);
  const [otherLabel, setOtherLabel] = useState('');
  const [noVariantAvailable, setNoVariantAvailable] = useState(false);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const { pathURL } = usePathNameContext();
  const [outOfStock, setOutOfStock] = useState(false);
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const [changeCurrentPageFlag, setChangeCurrentPageFlag] = useState(0);
  const [prevNextFlag, setPrevNextFlag] = useState('');

  const history = createBrowserHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);

  /* function get distinct value */
  const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_NAME,
            value: null,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_SELECTED_PRODUCT_NAME,
            value: null,
          })
        );
      }
    });
  }, [history, navigate]);

  useEffect(() => {
    (async () => {
      try {
        setIsExLoader(
          true
        ); /* set the loader true spinner will start in the screen */
        const VariantID = selectedProductDetailsState.variantID;

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/exchange/item/`,
          {
            shop: settingState.storeUniqueID,
            variant_id: VariantID,
            customer_email: productDetailsState.customerEmail,
            test_mode: productDetailsState.testMode,
            order_id: productDetailsState.recentOrderID,
            line_item_id: selectedProductDetailsState.lineItemID,
            quantity: parseInt(selectedProductDetailsState.selectedQuantity)
          }
        );
        if (response.data) {
          if (!exchangeSettingsState.newItemName) {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_NAME,
                value: response.data.data?.item_to_be_returned.name,
              })
            );
          }

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_SELECTED_PRODUCT_NAME,
              value: response.data.data?.item_to_be_returned.name,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_ALLOW_OUT_OF_STOCK,
              value: response.data.data?.allow_out_of_stock,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_ORDER_EXCHANGE_INFO,
              value: response.data,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_AVAILABLE_CREDITS,
              value: response.data.data?.available_credit,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_BONUS,
              value: response.data.data?.bonus,
            })
          );

          /* it will set the other tag to default title*/
          if (
            response.data.data?.variant_options.length === 1 &&
            response.data.data?.variant_options[0]?.name === 'Title' &&
            response.data.data?.variant_options[0]?.values.length === 1 &&
            response.data.data?.variant_options[0]?.values[0] ===
              'Default Title'
          ) {
            setNoVariantAvailable(true);
          }

          /* initialize the variation variable */
          let variation_color = [],
            variation_size = [],
            variation_other_tag = [];

          /* filtering distinct variation like color, size and other tag */
          response.data.data?.exchange_options?.variations.forEach((item) => {
            variation_color = [...variation_color, item.color].filter(distinct);
            variation_size = [...variation_size, item.size].filter(distinct);
            variation_other_tag = [
              ...variation_other_tag,
              item.other_tag,
            ].filter(distinct);
          });

          /* setting variation like color, size and other tag and Remove 
          null or empty value from the array variable   */

          setSizes(variation_size.filter((el) => el != null && el !== ''));
          setColors(variation_color.filter((el) => el != null && el !== ''));
          setOthers(
            variation_other_tag.filter((el) => el != null && el !== '')
          );

          /*setting Other Tag label */
          response.data.data?.variant_options.forEach((item) => {
            if (
              item.name !== 'Size' &&
              item.name !== 'Color' &&
              item.name !== 'Title'
            ) {
              setOtherLabel(item.name);
            }
          });

          setVariations(response.data.data?.exchange_options?.variations);
          if (!prevNextFlag) {
            setOtherOptions(
              response.data.data?.exchange_options?.other_options
            );
          }

          /* Setting Default selected value for color, size and other tag */
          setSelectedColor(
            response.data.data?.exchange_options?.variations[0]?.color
          );
          setSelectedSize(
            response.data.data?.exchange_options?.variations[0]?.size
          );
          setSelectedOthers(
            response.data.data?.exchange_options?.variations[0]?.other_tag
          );

          setProductMainView(
            response.data.data?.exchange_options?.variations[0]?.images[0]
          );

          setProductSideView(
            response.data.data?.exchange_options?.variations[0]?.images
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_SELECTED_COLOR,
              value: response.data.data?.exchange_options?.variations[0]?.color,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_SELECTED_SIZE,
              value: response.data.data?.exchange_options?.variations[0]?.size,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_SELECTED_OTHER,
              value:
                response.data.data?.exchange_options?.variations[0]?.other_tag,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_NEXT_URL,
              value: response.data.data?.exchange_options?.next_url,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_PREV_URL,
              value: response.data.data?.exchange_options?.prev_url,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_OTHER_OPTIONS_COUNT,
              value: response.data.data?.exchange_options?.other_options_count,
            })
          );

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_PAGINATED_BY,
              value: response.data.data?.exchange_options?.paginate_by,
            })
          );

          if (exchangeSettingsState.isCustomItem) {
            setProductMainView(
              response.data.data?.item_to_be_returned?.img_url
            );
          } else {
            setProductMainView(
              response.data.data?.exchange_options?.variations[0]?.images[0]
            );
          }

          setProductSideView(
            response.data.data?.exchange_options?.variations[0]?.images
          );

          let variant_tags = response.data.data?.item_to_be_returned?.tags;
          let variant_labels = [];
          response.data.data.variant_options.forEach((variant) => {
            variant_labels = [...variant_labels, variant.name];
          });

          variant_tags.map((item, index) => {
            return setItemToBeReturnedTags(
              (prev) => prev + `${variant_labels[index]} ${item},`
            );
          });

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_VARIANTS_LABELS,
              value: variant_labels,
            })
          );

          if (!response.data.data?.item_to_be_returned?.tags[1]) {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_COLOR,
                value: false,
              })
            );
          } else {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_COLOR,
                value: response.data.data?.item_to_be_returned?.tags[1],
              })
            );
          }

          if (!response.data.data?.item_to_be_returned?.tags[2]) {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_OTHER_TAG,
                value: false,
              })
            );
          } else {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_OTHER_TAG,
                value: response.data.data?.item_to_be_returned?.tags[2],
              })
            );
          }

          if (!response.data.data?.item_to_be_returned?.tags[0]) {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_SIZE,
                value: false,
              })
            );
          } else {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_SELECTED_ITEM_SIZE,
                value: response.data.data?.item_to_be_returned?.tags[0],
              })
            );
          }

          //Redirect to SEE ALL ITEMS screen if the item is custom
          if (response.data.data?.item_to_be_returned?.is_custom_item) {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_IS_CUSTOM_ITEM,
                value: response.data.data?.item_to_be_returned?.is_custom_item,
              })
            );
          } else {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_IS_CUSTOM_ITEM,
                value: false,
              })
            );
          }

          setIsExLoader(false);
        }
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate('/internal-server-error');
        }
      }
    })();
  }, [exchangeSettingsState.isCustomItem]);

  useEffect(() => {
    (async () => {
      try {
        if (changeCurrentPageFlag >= 1) {
          setIsExLoader(true);
          const StoreUniqueID = settingState.storeUniqueID;
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/exchange/item/get_paginated_exchange_items/`,
            {
              shop: StoreUniqueID,
              next_url:
                prevNextFlag === 'prev'
                  ? exchangeSettingsState.prevURL
                  : exchangeSettingsState.nextURL,
              line_item_id: selectedProductDetailsState.lineItemID,
              quantity: parseInt(selectedProductDetailsState.selectedQuantity)
            }
          );

          if (response?.status === 200) {
            setOtherOptions(response.data.data?.other_options);

            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_PREV_URL,
                value: response.data.data.prev_url,
              })
            );

            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_NEXT_URL,
                value: response.data.data.next_url,
              })
            );
          }
          setIsExLoader(false);
        }
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate('/internal-server-error');
        }
      }
    })();
  }, [changeCurrentPageFlag, navigate]);

  useEffect(() => {
    if (pathURL.toLowerCase() === '/exchange') {
      const filteredItem = variations.find(
        (item) =>
          item.size === selectedSize &&
          item.color.toLowerCase() === selectedColor.toLowerCase()
      );

      const updateSelectedVariantItemData = (item) => {
        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_PRICE,
            value: item.price,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_IMAGE,
            value: item.img_url,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_SIZE,
            value: item.size,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_COLOR,
            value: item.color,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_OTHER_TAG,
            value: item.other_tag,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_NAME,
            value: item.name,
          })
        );

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_EXCHANGE_VARIANT_ID,
            value: item.variant_id,
          })
        );

        setProductSideView(item.images);
        setProductMainView(item.img_url);
      };

      // If Selected Size, Color and Other Tag variant exists
      // If not exist, It automatically re-rendered with size and color that exists

      if (filteredItem) {
        updateSelectedVariantItemData(filteredItem);

        if (
          filteredItem.quantity === 0 &&
          !exchangeSettingsState.allowOutOfStock
        ) {
          setDisableContinueButton(true);
        } else setDisableContinueButton(false);
      }
      if (exchangeSettingsState.isCustomItem) {
        setDisableContinueButton(true);
      } else {
        setDisableContinueButton(false);
      }
      /* setting up size and other variants when user click on the variants */
      let variation_size = [],
        variation_other_tag = [];

      variations.map((item) => {
        if (item.color.toLowerCase() === selectedColor.toLowerCase()) {
          return (variation_size = [...variation_size, item.size].filter(
            distinct
          ));
        }
      });

      variations.map((item) => {
        if (
          item.color.toLowerCase() === selectedColor.toLowerCase() &&
          item.size === selectedSize
        ) {
          return (variation_other_tag = [
            ...variation_other_tag,
            item.other_tag,
          ].filter(distinct));
        }
      });

      if (variation_other_tag.length === 0) {
        setSelectedSize((prev) => {
          return (prev = variation_size[0]);
        });

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_SIZE,
            value: variation_size[0],
          })
        );

        setSizeID((prev) => {
          return (prev = 0);
        });

        setOtherID(0);

        setSelectedOthers((prev) => {
          return (prev = variation_other_tag[0]);
        });
      }

      if (!selectedOthers) {
        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_OTHER_TAG,
            value: variation_other_tag[0],
          })
        );
      } else {
        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_OTHER_TAG,
            value: selectedOthers,
          })
        );
      }

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_COLOR,
          value: selectedColor,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NEW_ITEM_SIZE,
          value: selectedSize,
        })
      );

      setSizes(variation_size);
      setOthers(variation_other_tag);
    }
  }, [
    selectedColor,
    selectedSize,
    variations,
    selectedOthers,
    pathURL,
    exchangeSettingsState.isCustomItem,
    exchangeSettingsState.allowOutOfStock,
    dispatch,
  ]);

  return {
    colors,
    setColorID,
    setSelectedColor,
    colorID,
    sizes,
    setSizeID,
    setSelectedSize,
    sizeID,
    productSideView,
    productMainView,
    itemToBeReturnedTags,
    isExLoader,
    setIsExLoader,
    otherOptions,
    others,
    setOthers,
    otherLabel,
    noVariantAvailable,
    selectedOthers,
    setSelectedOthers,
    otherID,
    setOtherID,
    setProductMainView,
    setProductSideView,
    setSizes,
    setColors,
    outOfStock,
    setChangeCurrentPageFlag,
    setPrevNextFlag,
    isOutOfStock,
    disableContinueButton,
  };
};
