import React, { useEffect } from "react";
import { Tabs, TabPanel } from "react-tabs";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FullPageLoader } from "..";
import { useExchangeFetch } from "../../hooks";
import "react-tabs/style/react-tabs.css";
import {
  removeInsignificantZero,
  spaceAfterComma,
  htmlColorPicker,
} from "../../Utilities";
import "./exchange.css";

export const Exchange = () => {
  const {
    colors,
    setColorID,
    setSelectedColor,
    colorID,
    sizes,
    setSizeID,
    setSelectedSize,
    sizeID,
    productSideView,
    productMainView,
    itemToBeReturnedTags,
    otherOptions,
    others,
    otherLabel,
    noVariantAvailable,
    setSelectedOthers,
    otherID,
    setOtherID,
    isExLoader,
    isOutOfStock,
    disableContinueButton,
  } = useExchangeFetch();

  const navigate = useNavigate();
  const productDetailsState = useSelector((state) => state.productDetails);
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);

  const buttonHandler = () => {
    navigate("/ExchangeReview");
  };

  const backHandler = () => {
    /* it will navigate to the previous page */
    window.history.back();
  };

  const seeAll = () => {
    navigate("/OtherItems");
  };

  return (
    <>
      {isExLoader ? (
        <FullPageLoader />
      ) : (
        <main className="option choose exchange_main">
          <div className="container ">
            <div className="option__row">
              <div className="option__wrapper">
                <div className="row__title mobile_hide">
                  <Link
                    to=""
                    onClick={backHandler}
                    className="btn__small_screen"
                  >
                    <img src="assets/svg/chevron_left.svg" alt="back" />
                  </Link>
                  <h1 className="option__title title">Exchange option</h1>
                </div>
                <div className="option__panel">
                  <Tabs>
                    <TabPanel>
                      <h2 className="option__subtitle">
                        {noVariantAvailable
                          ? "No Other variants exists, exchange with the same product"
                          : `${
                              isOutOfStock
                                ? "Out of Stock"
                                : "Exchange for Other variants"
                            }`}
                      </h2>
                      <h6 className="option__subtitle">
                        {exchangeSettingsState.selectedProductName}
                      </h6>
                      {noVariantAvailable || (
                        <>
                          <div
                            className={`option__colors ${
                              colors.length === 0 && "d-none"
                            }`}
                          >
                            <span className="option__name">Color</span>
                            <div className="option__choise">
                              {colors.map((item, index) => {
                                return (
                                  <span
                                    key={index}
                                    onClick={() => {
                                      setColorID(index);
                                      setSelectedColor(item);
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name="color"
                                      className="option__choise_radio"
                                      id={`color-${index}`}
                                      checked={index === colorID}
                                    />
                                    <label
                                      htmlFor={`color-${index}`}
                                      className="option__choise_label label__color"
                                      title={item}
                                    >
                                      <span
                                        className="option__choise_circle"
                                        data-color="#D94036"
                                        style={{
                                          background: htmlColorPicker(item),
                                        }}
                                      >
                                        <span
                                          className="option__choise_ring"
                                          style={{
                                            border: `2px solid ${htmlColorPicker(
                                              item
                                            )}`,
                                          }}
                                        ></span>
                                      </span>
                                    </label>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                          <div
                            className={`option__size ${
                              (sizes?.length === 0 || sizes[0]?.length === 0) &&
                              "d-none"
                            }`}
                          >
                            <span className="option__name">Size</span>
                            <div className="option__choise">
                              {sizes?.map((item, index) => {
                                return (
                                  <span
                                    onClick={() => {
                                      setSizeID(index);
                                      setSelectedSize(item);
                                    }}
                                    key={index}
                                  >
                                    <input
                                      type="radio"
                                      name="size"
                                      className="option__choise_radio"
                                      id={`size-${index}`}
                                      checked={index === sizeID}
                                    />
                                    <label
                                      htmlFor={`size-${index}`}
                                      className="option__choise_label
                                        label__size"
                                    >
                                      <span className="option__choise_square">
                                        {item}
                                      </span>
                                    </label>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                          <div
                            className={`option__size ${
                              (others?.length === 0 ||
                                others[0]?.length === 0) &&
                              "d-none"
                            }`}
                          >
                            <span className="option__name">{otherLabel}</span>
                            <div className="option__choise">
                              {others.map((item, index) => {
                                return (
                                  <span
                                    onClick={() => {
                                      setOtherID(index);
                                      setSelectedOthers(item);
                                    }}
                                    key={index}
                                  >
                                    <input
                                      type="radio"
                                      name="other"
                                      className="option__choise_radio"
                                      id={`other-${index}`}
                                      checked={index === otherID}
                                    />
                                    <label
                                      htmlFor={`other-${index}`}
                                      className="option__choise_label
                                        label__size"
                                    >
                                      <span className="option__choise_square">
                                        {item}
                                      </span>
                                    </label>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      )}
                    </TabPanel>
                    <TabPanel>
                      <div className="option__items_content">
                        {otherOptions?.slice(0, 2).map((item) => {
                          return (
                            <div className="option__items_card">
                              <div
                                className="option__items_picture"
                                key={item.variant_id}
                              >
                                <img
                                  src={item.img_url}
                                  alt=""
                                  className="option__items_img"
                                />
                              </div>
                              <div className="option__items_info">
                                <h3 className="option__items_title">
                                  {item.name}
                                </h3>
                                <div className="option__price">
                                  <span className="option__price_new">
                                    {productDetailsState.currency}
                                    {item.price}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <button
                        type="button"
                        className="btn
                                btn__continue button"
                        id="other-btn"
                        onClick={seeAll}
                      >
                        See All
                      </button>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
              <div className="option__right">
                <div className="option__screen">
                  <ul className="option__list">
                    {productSideView?.map((item, index) => {
                      return (
                        <li className="option__list_item active" key={index}>
                          <img
                            src={item}
                            alt="sideview"
                            className="option__list_img"
                            id={`targetImg1 ${index}`}
                          />
                        </li>
                      );
                    })}
                    {isOutOfStock && (
                      <li className="option__list_item active">
                        <img
                          src={exchangeSettingsState.selectedProductImage}
                          alt="sideview"
                          className="option__list_img"
                        />
                      </li>
                    )}
                  </ul>
                  <div className="option__target">
                    <img
                      src={
                        isOutOfStock
                          ? exchangeSettingsState.selectedProductImage
                          : productMainView
                      }
                      alt="mainview"
                      className="option__target_img"
                      width="250"
                      height="300"
                    />
                    {/* <p className="exchange__card_desc">
                      {exchangeSettingsState.selectedProductName}
                    </p> */}
                    {disableContinueButton && (
                      <span
                        className="info__error error-items"
                        style={{ padding: "10px 40px", margin: "12%" }}
                      >
                        OUT OF STOCK
                      </span>
                    )}
                  </div>
                </div>
                <div className="option__advert advert">
                  <h2 className="advert__title">Exchange Value</h2>
                  <div className="advert__item">
                    <p className="advert__price">
                      {productDetailsState.currency}
                      <span className="advert__price_value">
                        {removeInsignificantZero(
                          parseFloat(exchangeSettingsState.availableCredit)
                        )}
                      </span>
                    </p>
                    <p className="advert__size">
                      {spaceAfterComma(
                        itemToBeReturnedTags?.substring(
                          0,
                          itemToBeReturnedTags?.length - 1
                        )
                      )}
                    </p>
                  </div>
                  <p
                    className={`advert__bonus ${
                      exchangeSettingsState.bonus === 0 && "d-none"
                    }`}
                  >
                    +{productDetailsState.currency}
                    <span className="advert__bonus_value">
                      {exchangeSettingsState.bonus}
                    </span>{" "}
                    Bonus
                  </p>
                  <img
                    src="assets/sbg.webp"
                    className="advert__bg_img"
                    alt="bg"
                  />
                </div>
              </div>
              <div
                className="row col-md-12 btn-width-100"
                style={{ paddingTop: "20px" }}
              >
                <div
                  className={`col-md-5 see-all-container ${
                    otherOptions.length > 0 ? "" : "hide_see_all"
                  }`}
                >
                  <div className="mobile-center">
                    <button
                      className=" btn   back_page
                      btn_all_items "
                      onClick={seeAll}
                      style={{
                        // border: "none",
                        // background: "none",

                        cursor: "cursor",
                      }}
                      disabled={otherOptions.length > 0 ? false : true}
                    >
                      SEE ALL ITEMS
                    </button>
                  </div>
                </div>

                <div className="col-md-7 mobile-back">
                  <div className="row  justify-content-start align-items-center">
                    <button
                      className="btn btn__back back_page btn__big_screen btn-back-big-device"
                      onClick={backHandler}
                    >
                      Back
                    </button>
                    <button
                      className={`form__btn btn-continue-big-device ${
                        disableContinueButton && "form__btn__disabled"
                      }`}
                      onClick={buttonHandler}
                      disabled={disableContinueButton}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>

              <div className="row__title mobile_show">
                <Link to="/" className="btn__small_screen">
                  <img src="assets/svg/chevron_left.svg" alt="back" />
                </Link>
                <h1 className="option__title title">Exchange option</h1>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};
