const storeSettingTypes = {
  UPDATE_PLATFORM: "UPDATE_PLATFORM",
  UPDATE_SUBDOMAIN: "UPDATE_SUBDOMAIN",
  UPDATE_IS_JOHN_DOE_TEST_MODE: "UPDATE_IS_JOHN_DOE_TEST_MODE",
  UPDATE_BRAND_LOGO: "UPDATE_BRAND_LOGO",
  UPDATE_BASE_URL: "UPDATE_BASE_URL",
  UPDATE_AUTO_ORDER_ID: "UPDATE_AUTO_ORDER_ID",
  UPDATE_AUTO_CID: "UPDATE_AUTO_CID",
  UPDATE_STORE_UNIQUE_ID: "UPDATE_STORE_UNIQUE_ID",
  UPDATE_PRIMARY_COLOR: "UPDATE_PRIMARY_COLOR",
  UPDATE_SECONDARY_COLOR: "UPDATE_SECONDARY_COLOR",
  UPDATE_CUSTOM_FONT: "UPDATE_CUSTOM_FONT",
  UPDATE_RESPONSE_MESSAGE: "UPDATE_RESPONSE_MESSAGE",
  UPDATE_IS_404: "UPDATE_IS_404",
  UPDATE_RETURN_POLICY_URL: "UPDATE_RETURN_POLICY_URL",
};

const productActionTypes = {
  SET_OTHER_ITEM_PRODUCT_DETAILS_URL: "SET_OTHER_ITEM_PRODUCT_DETAILS_URL",
  SET_CUSTOMER_ID: "SET_CUSTOMER_ID",
  SET_CUSTOMER_EMAIL: "SET_CUSTOMER_EMAIL",
  SET_ORDER_NUMBER: "SET_ORDER_NUMBER",
  SET_EXCHANGE_ITEM: "SET_EXCHANGE_ITEM",
  SET_ENABLE_INCENTIVE: "SET_ENABLE_INCENTIVE",
  SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
  SET_TEST_MODE: "SET_TEST_MODE",
  SET_RETURN_REASON: "SET_RETURN_REASON",
  SET_RETURN_REASON_ID: "SET_RETURN_REASON_ID",
  SET_RECENT_ORDER_ID: "SET_RECENT_ORDER_ID",
  SET_COLLECT_PRICE_DIFFERENCE_PERCENT: "SET_COLLECT_PRICE_DIFFERENCE_PERCENT",
  SET_COLLECT_PRICE_DIFFERENCE_PERCENT_VALUE:
    "SET_COLLECT_PRICE_DIFFERENCE_PERCENT_VALUE",
  SET_ORIGINAL_PAYMENT_METHOD_REFUND_AVAILABLE:
    "SET_ORIGINAL_PAYMENT_METHOD_REFUND_AVAILABLE",
  SET_ENABLESTORE_CREDIT_REFUND: "SET_ENABLESTORE_CREDIT_REFUND",
  SET_ASK_ACCOUNT_INFORMATION: "SET_ASK_ACCOUNT_INFORMATION",
  SET_CHARGE_REVERSE_SHIPMENT_EXCHANGE: "SET_CHARGE_REVERSE_SHIPMENT_EXCHANGE",
  SET_REVERSE_SHIPMENT_FEE: "SET_REVERSE_SHIPMENT_FEE",
  SET_COLLECT_PRICE_DIFFERENCE: "SET_COLLECT_PRICE_DIFFERENCE",
  SET_COLLECT_PRICE_DIFFERENCE_AMOUNT: "SET_COLLECT_PRICE_DIFFERENCE_AMOUNT",
  SET_CURRENCY: "SET_CURRENCY",
  SET_NEIGHBORHOOD_COMMERCE: "SET_NEIGHBORHOOD_COMMERCE",
  SET_GET_REFUND: "SET_GET_REFUND",
  SET_IS_SHOW_REFUND: "SET_IS_SHOW_REFUND",
  SET_ORDER_INFO: "SET_ORDER_INFO",
  SET_REASON_DETAILS: "SET_REASON_DETAILS",
};

const selectedProductActionTypes = {
  SET_VARIANT_ID: "SET_VARIANT_ID",
  SET_ITEM_EXCHANGE_ALLOWED: "SET_ITEM_EXCHANGE_ALLOWED",
  SET_RETURN_ALLOWED: "SET_RETURN_ALLOWED",
  SET_SELECTED_PRODUCT_IMAGE: "SET_SELECTED_PRODUCT_IMAGE",
  SET_SELECTED_PRODUCT_PRICE: "SET_SELECTED_PRODUCT_PRICE",
  SET_LINE_ITEM_ID: "SET_LINE_ITEM_ID",
  SET_SELECTED_ITEM_QUANTITY: "SET_SELECTED_ITEM_QUANTITY",
};

const exchangeSettingsActionTypes = {
  UPDATE_IS_IMAGE_UPLOAD: "UPDATE_IS_IMAGE_UPLOAD",
  UPDATE_IS_MULTIPLE_IMAGES_UPLOAD: "UPDATE_IS_MULTIPLE_IMAGES_UPLOAD",
  UPDATE_IS_SINGLE_IMAGE_UPLOAD: "UPDATE_IS_SINGLE_IMAGE_UPLOAD",
  UPDATE_ALLOW_EXCHANGE: "UPDATE_ALLOW_EXCHANGE",
  UPDATE_ALLOW_RETURN: "UPDATE_ALLOW_RETURN",
  UPDATE_FINAL_EXCHANGE: "UPDATE_FINAL_EXCHANGE",
  UPDATE_IS_ENABLE_REFUND: "UPDATE_IS_ENABLE_REFUND",
  UPDATE_FINAL_INCENTIVE: "UPDATE_FINAL_INCENTIVE",
  UPDATE_NAVIGATE_BACK: "UPDATE_NAVIGATE_BACK",
  UPDATE_NAVIGATE_FORWARD: "UPDATE_NAVIGATE_FORWARD",
  UPDATE_NEW_ITEM_SIZE: "UPDATE_NEW_ITEM_SIZE",
  UPDATE_NEW_ITEM_OTHER_TAG: "UPDATE_NEW_ITEM_OTHER_TAG",
  UPDATE_NEW_ITEM_COLOR: "UPDATE_NEW_ITEM_COLOR",
  UPDATE_NEW_ITEM_NAME: "UPDATE_NEW_ITEM_NAME",
  UPDATE_SELECTED_PRODUCT_NAME: "UPDATE_SELECTED_PRODUCT_NAME",
  UPDATE_ALLOW_OUT_OF_STOCK: "UPDATE_ALLOW_OUT_OF_STOCK",
  UPDATE_ORDER_EXCHANGE_INFO: "UPDATE_ORDER_EXCHANGE_INFO",
  UPDATE_AVAILABLE_CREDITS: "UPDATE_AVAILABLE_CREDITS",
  UPDATE_BONUS: "UPDATE_BONUS",
  UPDATE_NEW_ITEM_PRICE: "UPDATE_NEW_ITEM_PRICE",
  UPDATE_NEW_ITEM_IMAGE: "UPDATE_NEW_ITEM_IMAGE",
  UPDATE_EXCHANGE_VARIANT_ID: "UPDATE_EXCHANGE_VARIANT_ID",
  UPDATE_SELECTED_COLOR: "UPDATE_SELECTED_COLOR",
  UPDATE_SELECTED_SIZE: "UPDATE_SELECTED_SIZE",
  UPDATE_SELECTED_OTHER: "UPDATE_SELECTED_OTHER",
  UPDATE_NEXT_URL: "UPDATE_NEXT_URL",
  UPDATE_PREV_URL: "UPDATE_PREV_URL",
  UPDATE_OTHER_OPTIONS_COUNT: "UPDATE_OTHER_OPTIONS_COUNT",
  UPDATE_PAGINATED_BY: "UPDATE_PAGINATED_BY",
  UPDATE_VARIANTS_LABELS: "UPDATE_VARIANTS_LABELS",
  UPDATE_SELECTED_ITEM_COLOR: "UPDATE_SELECTED_ITEM_COLOR",
  UPDATE_SELECTED_ITEM_OTHER_TAG: "UPDATE_SELECTED_ITEM_OTHER_TAG",
  UPDATE_SELECTED_ITEM_SIZE: "UPDATE_SELECTED_ITEM_SIZE",
  UPDATE_SELECTED_OTHER_VARIATION_OBJECT:
    "UPDATE_SELECTED_OTHER_VARIATION_OBJECT",
  UPDATE_IMAGE_LIST: "UPDATE_IMAGE_LIST",
};

const paymentSettingTypes = {
  UPDATE_PAYMENT_REQUIRED: "UPDATE_PAYMENT_REQUIRED",
  UPDATE_PAYMENT_LINK: "UPDATE_PAYMENT_LINK",
  UPDATE_PAYMENT_ID: "UPDATE_PAYMENT_ID",
};

const refundSettingsActionTypes = {
  UPDATE_INCENTIVE: "UPDATE_INCENTIVE",
  UPDATE_THANKYOU_HEADING: "UPDATE_THANKYOU_HEADING",
  UPDATE_THANKYOU_DESCRIPTION: "UPDATE_THANKYOU_DESCRIPTION",
  UPDATE_THANKYOU_CARD_HEADING: "UPDATE_THANKYOU_CARD_HEADING",
  UPDATE_KEEP_THE_ITEM_AMOUNT: "UPDATE_KEEP_THE_ITEM_AMOUNT",
  UPDATE_KEEP_THE_ITEM_AMOUNT_DESCRIPTION:
    "UPDATE_KEEP_THE_ITEM_AMOUNT_DESCRIPTION",
  UPDATE_REFUND_ORIGINAL_INCENTIVE: "UPDATE_REFUND_ORIGINAL_INCENTIVE",
  UPDATE_REFUND_CREDIT_INCENTIVE: "UPDATE_REFUND_CREDIT_INCENTIVE",
  UPDATE_INCENTIVE_MESSAGE: "UPDATE_INCENTIVE_MESSAGE",
  UPDATE_REFUND_OPTION: "UPDATE_REFUND_OPTION",
};

export {
  storeSettingTypes,
  productActionTypes,
  selectedProductActionTypes,
  exchangeSettingsActionTypes,
  paymentSettingTypes,
  refundSettingsActionTypes,
};
