import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateExchangeSettingsDetails } from "../../redux/slices";
import { exchangeSettingsActionTypes } from "../../redux/utils";
import { HeaderText, Button } from "../";
import { useOrderContext } from "../../contexts";
import "./uploadImg.css";

export const UploadImg = () => {
  const [multiImg, setMultiImg] = useState([]);
  const [multi, setMulti] = useState();
  const [isError, setIsError] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isVideo, setIsVideo] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const FILESIZELIMIT = 10485760; //10MB file size limit

  const dispatch = useDispatch();
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);

  const getBase64 = async (file) => {
    const response = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    return response;
  };

  const setImgPreview = async (e) => {
    const getFileType = e.target.files[0].type.slice(0, 5);
    const getFileSize = e.target.files[0].size;
    getFileType === "video" ? setIsVideo(true) : setIsVideo(false);

    setIsLoader(true);
    if (
      (getFileType === "image" || getFileType === "video") &&
      getFileSize <= FILESIZELIMIT
    ) {
      setIsError(false);
      setErrorMsg("");
      if (e.target?.files[0]) {
        const response = await getBase64(e.target.files[0]);
        setIsLoader(response);

        dispatch(
          updateExchangeSettingsDetails({
            key: exchangeSettingsActionTypes.UPDATE_IMAGE_LIST,
            value: [...multiImg, response],
          })
        );

        setMultiImg((prevState) => {
          return [...prevState, response];
        });
      }
    } else {
      setIsError(true);
      getFileSize > FILESIZELIMIT
        ? setErrorMsg("File Should be less than 50 MB")
        : setErrorMsg("Only image is allowed");
    }
  };

  const [showAddMore, setShowAddMore] = useState(false);
  const { setIsSelected } = useOrderContext();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const response = exchangeSettingsState.isMultipleImagesUpload;

        if (response) {
          setMulti(response);
        } else {
          setMulti(false);
        }
      } catch (err) {
        console.log(`${err}:unable to set  state`);
      }
    })();
  }, []);

  const removeImgHandler = (imageID) => {
    if (multiImg?.length) {
      //Delete item
      const filterID = multiImg.filter((imgID, index) => index !== imageID);

      setMultiImg(filterID);
      setIsLoader(true);
    }
  };

  useEffect(() => {
    multiImg?.length > 0 ? setIsSelected(true) : setIsSelected(false);

    multiImg?.length > 0 && multi === true
      ? setShowAddMore(true)
      : setShowAddMore(false);
  }, [multiImg, setIsSelected]);

  useEffect(() => {
    if (multiImg.length > 0) {
      (async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/common/upload_image/`,

            {
              files: multiImg,
            }
          );

          if (response) {
            dispatch(
              updateExchangeSettingsDetails({
                key: exchangeSettingsActionTypes.UPDATE_IMAGE_LIST,
                value: response.data.toString().replace(/,/g, ";"),
              })
            );

            setIsLoader(null);
          }
        } catch (error) {
          if (error?.response?.status === 500) {
            navigate("/internal-server-error");
          }
        }
      })();
    }
  }, [multiImg]);

  const uploadHandler = () => {
    if (exchangeSettingsState.navigateForward === "/exchange") {
      navigate("/ExchangePayment");
    }
    if (exchangeSettingsState.navigateForward === "/photo") {
      navigate("/refund");
    }
  };

  /* Navigate to Previous Page */
  const backHandler = () => {
    window.history.back();
  };

  return (
    <main className="photo">
      <div className="container">
        <div className="row justify-content-center row__title">
          <Link to="" onClick={backHandler} className="btn__small_screen">
            <img src="assets/svg/chevron_left.svg" alt="back" />
          </Link>
          <div className="col-10 col-sm-12">
            <HeaderText
              text="Upload an image of the item you wish to"
              extraText={`${
                exchangeSettingsState.navigateForward === "/exchange"
                  ? "exchange"
                  : "return"
              }`}
            />
          </div>
        </div>
        <div className="row d-flex align-items-center   justify-content-center photo__row">
          {multiImg?.map((item, index) => {
            return (
              <div
                className={`photo__card col-6 col-md-5 col-xl-3 photo__one d-flex justify-content-center align-items-center`}
                key={index}
              >
                {isLoader === item ? (
                  <div className="photo__load">
                    <p className="photo__load_text">Uploading your files</p>
                    <div className="progress-bar-container">
                      <div className="progress-bar">
                        <div className="progress-bar-value"></div>
                      </div>
                    </div>
                  </div>
                ) : isVideo ? (
                  <video width="320" height="240" controls>
                    <source src={item} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={item}
                    className="photo__card_add"
                    alt="photo_container"
                    width="200"
                    height="290"
                  />
                )}
                <span
                  className="photo__card_delete"
                  onClick={() => {
                    setShowAddMore(true);
                  }}
                >
                  <img
                    src="assets/svg/delete.svg"
                    alt="close"
                    className="photo__card_icon"
                    onClick={() => removeImgHandler(index)}
                  />
                </span>
                <div className="photo__load" style={{ zIndex: "-1" }}>
                  <p className="photo__load_text">Uploading your files</p>
                  <div className="photo__load_line">
                    <span
                      className="photo__load_progress"
                      style={{ width: "100%" }}
                    ></span>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className={` ${
              showAddMore ? "" : "d-none"
            } photo__card col-6 col-md-5 col-xl-3 photo__one d-flex justify-content-center align-items-center `}
          >
            <div
              className={`d-flex justify-content-center align-items-center photo__empty call_table`}
            >
              <span
                className={`  ${multi ? "" : "d-none"}`}
                style={{ width: "260px" }}
              >
                <label
                  htmlFor="cameraFileInput"
                  className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
                  style={{
                    width: "inherit",
                    height: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src="assets/svg/photo-icon.svg"
                    alt="camera"
                    className="cursor-pointer one"
                  />
                  <input
                    className="d-none"
                    id="cameraFileInput"
                    type="file"
                    accept=".svg,.jpg,.jpeg.,.gif,.png,.webp,.mp4"
                    capture="environment"
                    onChange={(e) => {
                      setImgPreview(e);
                    }}
                  />
                </label>
              </span>
            </div>
          </div>

          {multiImg.length === 0 && (
            <div
              className={`photo__card col-6 col-md-5 col-xl-3 photo__one d-flex justify-content-center align-items-center `}
            >
              <div
                className={` d-flex justify-content-center align-items-center photo__empty call_table ${
                  multiImg?.length > 0 && multi === "false" ? "d-none" : ""
                } `}
                style={{ width: "260px" }}
              >
                <label
                  htmlFor="cameraFileInput"
                  className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
                  style={{
                    width: "inherit",
                    height: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src="assets/svg/photo-icon.svg"
                    alt="camera"
                    className="cursor-pointer two"
                  />
                  <input
                    className="d-none "
                    id="cameraFileInput"
                    type="file"
                    accept=".svg,.jpg,.jpeg.,.gif,.png,.webp,.mp4"
                    capture="environment"
                    onChange={(e) => {
                      setImgPreview(e);
                    }}
                  />
                </label>
              </div>
            </div>
          )}
          {isError && (
            <div className="row justify-content-center">
              <span className="info__error error-items">{errorMsg}</span>
            </div>
          )}
        </div>

        <div className="row btns">
          <div className="col-6 col-md-4 col-lg-3 col-xl-2 btn__big_screen">
            <button
              href="!#"
              className="btn btn__back back-page"
              onClick={backHandler}
            >
              Back
            </button>
          </div>

          <div className="col-12 col-md-4 col-lg-3 col-xl-2">
            <Button
              name={"Continue"}
              onClick={uploadHandler}
              disabled={isLoader ? true : false}
            />
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-3 col-xl-2"></div>
      </div>
    </main>
  );
};
