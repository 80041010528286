import { createSlice } from "@reduxjs/toolkit";
import { storeSettingTypes } from "../utils";

const storeSettingsSlice = createSlice({
  name: "storeSettings",

  initialState: {
    platform: null,
    storeSubDomain: null,
    isJaneDoeTestMode: null,
    brandLogo: null,
    baseURL: null,
    autoOrderId: null,
    autoCID: null,
    storeUniqueID: null,
    primaryColor: "#473e9e",
    secondaryColor: "#918bc5",
    customFont: null,
    responseMessage: null,
    is404: null,
    returnPolicyURL: null,
  },

  reducers: {
    updateSetting: (state, action) => {
      const { key, value } = action.payload;

      switch (key) {
        case storeSettingTypes.UPDATE_PLATFORM:
          state.platform = value;
          break;
        case storeSettingTypes.UPDATE_SUBDOMAIN:
          state.storeSubDomain = value;
          break;
        case storeSettingTypes.UPDATE_IS_JOHN_DOE_TEST_MODE:
          state.isJaneDoeTestMode = value;
          break;
        case storeSettingTypes.UPDATE_BRAND_LOGO:
          state.brandLogo = value;
          break;
        case storeSettingTypes.UPDATE_BASE_URL:
          state.baseURL = value;
          break;
        case storeSettingTypes.UPDATE_AUTO_ORDER_ID:
          state.autoOrderId = value;
          break;
        case storeSettingTypes.UPDATE_AUTO_CID:
          state.autoCID = value;
          break;
        case storeSettingTypes.UPDATE_STORE_UNIQUE_ID:
          state.storeUniqueID = value;
          break;
        case storeSettingTypes.UPDATE_PRIMARY_COLOR:
          state.primaryColor = value;
          break;
        case storeSettingTypes.UPDATE_SECONDARY_COLOR:
          state.secondaryColor = value;
          break;
        case storeSettingTypes.UPDATE_CUSTOM_FONT:
          state.customFont = value;
          break;
        case storeSettingTypes.UPDATE_RESPONSE_MESSAGE:
          state.responseMessage = value;
          break;
        case storeSettingTypes.UPDATE_IS_404:
          state.is404 = value;
          break;
        case storeSettingTypes.UPDATE_RETURN_POLICY_URL:
          state.returnPolicyURL = value;
          break;
        default:
      }
    },
  },
});

// Export the slice
export { storeSettingsSlice };

// Export the actions
export const { updateSetting } = storeSettingsSlice.actions;

// Export the reducer
export default storeSettingsSlice.reducer;
