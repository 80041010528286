import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BackContinue, HeaderText } from '../';
import { useOrderContext } from '../../contexts';

export const Options = () => {
  const [optionID, setOptionID] = useState();

  const { setIsSelected } = useOrderContext();
  const productDetailsState = useSelector((state) => state.productDetails);

  const exchangeOptions = [
    {
      id: 1,
      name: 'Request a Shipping label',
    },
    {
      id: 2,
      name: 'Drop to nearby store',
    },
    {
      id: 3,
      name: 'Print shipping label',
    },
    {
      id: 4,
      name: `Request a home pickup cost ${productDetailsState.currency}5`,
    },
  ];

  return (
    <main className="p8 choose">
      <div className="container">
        <div className="row justify-content-center row__title">
          <HeaderText text="Exchange option" />
        </div>
        <div className="row justify-content-center choose__row">
          {exchangeOptions.map((item) => {
            return (
              <div
                className="col-12 col-sm-6 col-md-5 col-xl-3 active"
                key={item.id}
                onClick={() => {
                  setOptionID(item.id);
                  setIsSelected(true);
                }}
              >
                <div
                  className={`choose__card ${
                    optionID === item.id ? 'active' : ''
                  }`}
                >
                  <p className="choose__desc">{item.name}</p>
                  <label className="choose__label">
                    <input
                      className="choose__input"
                      type="radio"
                      name="choose"
                    />
                    <span className="choose__input_circle"></span>
                  </label>
                </div>
              </div>
            );
          })}
        </div>

        <BackContinue navigateTo="/ExchangePayment" backTo="/exchange" />
      </div>
    </main>
  );
};
