import React, { useState } from "react";
import { Button } from "..";
import { useNavigate } from "react-router-dom";
import { useOrderContext } from "../../contexts";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { updateRefundSettingsDetails } from "../../redux/slices";
import { refundSettingsActionTypes } from "../../redux/utils";
import "./refundOptions.css";

export const RefundOptions = () => {
  const { setIsSelected } = useOrderContext();
  const [refundType, setRefundType] = useState();
  const [refundOption, setRefundOption] = useState();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  const dispatch = useDispatch();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);
  const refundSettingsState = useSelector((state) => state.refundSettings);

  /*it will decide whether to  navigate to page where user fill there bank ac detail*/
  const isNavigateToBankAccountInfo =
    productDetailsState.paymentMethod.toUpperCase() === "COD" &&
    productDetailsState.originalPaymentMethodRefundAvailable &&
    productDetailsState.askAccountInformation;

  /* Refund Option and there detail is setting up */
  const refundOptionItems = [
    {
      id: "1",
      name: "Original Payment",
      desc: "Your refund may take 5-6 days to get credited to your original payment method after the approval of your return request.",
      isEnable: "true",
      isShow: productDetailsState.originalPaymentMethodRefundAvailable,
    },
    {
      id: "2",
      name: "Store Credit",
      desc: `Your refund to Store credits may take 1-2 days after approval of your return request.`,
      isEnable: "true",
      isShow: productDetailsState.enableStoreCreditRefund,
    },
  ];

  const buttonHandler = () => {
    (async () => {
      try {
        setIsLoader(true);

        const VariantID = selectedProductDetailsState.variantID;
        const StoreUniqueID = settingState.storeUniqueID;
        const OrderInfo = productDetailsState.orderInfo;

        dispatch(
          updateRefundSettingsDetails({
            key: refundSettingsActionTypes.UPDATE_REFUND_OPTION,
            value: refundOption,
          })
        );

        const customerID = OrderInfo.customer_id;

        if (refundType === "Original Payment") {
          dispatch(
            updateRefundSettingsDetails({
              key: refundSettingsActionTypes.UPDATE_THANKYOU_HEADING,
              value: "Request Submitted",
            })
          );

          dispatch(
            updateRefundSettingsDetails({
              key: refundSettingsActionTypes.UPDATE_THANKYOU_DESCRIPTION,
              value:
                "We are processing your refund. It will be refunded to your original mode of payment after approval.",
            })
          );

          dispatch(
            updateRefundSettingsDetails({
              key: refundSettingsActionTypes.UPDATE_THANKYOU_CARD_HEADING,
              value: "To Original Method",
            })
          );

          dispatch(
            updateRefundSettingsDetails({
              key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT,
              value: refundSettingsState.refundOriginalIncentive,
            })
          );

          dispatch(
            updateRefundSettingsDetails({
              key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT_DESCRIPTION,
              value: "minus any charges will be refunded",
            })
          );
        }

        if (isNavigateToBankAccountInfo && refundType === "Original Payment") {
          navigate("/Cod");
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/ra/submit_return_request/`,
            {
              test_mode: productDetailsState.testMode,
              store_name: StoreUniqueID,
              email: productDetailsState.customerEmail,
              order_number: productDetailsState.orderNumber,
              order_id: productDetailsState.recentOrderID,
              customer_id: customerID,
              variant_id: VariantID,
              refund_to: refundOption,
              is_refund: true,
              image_list: exchangeSettingsState.imageList,
              reason_id: productDetailsState.returnReasonID,
              other_reason_desc: productDetailsState.reasonDetails,
              line_item_id: selectedProductDetailsState.lineItemID,
              quantity: parseInt(selectedProductDetailsState.selectedQuantity),
            }
          );

          if (response?.status === 200) {
            setIsLoader(false);

            if (refundType === "Original Payment") {
              navigate("/ThankYou");
            } else if (refundType === "Store Credit") {
              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_HEADING,
                  value: "Request Submitted",
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_DESCRIPTION,
                  value:
                  "Your return request RAN# "+response.data.ran+" has been submitted, We will start processing it shortly. Refund will be processed after the item has been received by us.",
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_THANKYOU_CARD_HEADING,
                  value: "As Store Credit",
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT,
                  value: refundSettingsState.refundOriginalIncentive,
                })
              );

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT_DESCRIPTION,
                  value: "minus any charges will be refunded",
                })
              );

              navigate("/ThankYou");
            }
          }
        }
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }
    })();
  };
  /* navigate to previous page */
  const backHandler = () => {
    window.history.back();
  };

  return (
    <main className="p12_rm choose">
      <div className="container">
        <div className="row justify-content-center row__title">
          <Link to="" onClick={backHandler} className="btn__small_screen">
            <img src="assets/svg/chevron_left.svg" alt="back" />
          </Link>
          <div className="col-12">
            <h1 className="p12__title title">Refund Method</h1>
          </div>
        </div>
        <div className="d-flex justify-content-center p12__row_rm">
          {refundOptionItems.map((item, index) => {
            return (
              item.isShow && (
                <div
                  className={`d-flex justify-content-between  choose__card_rm cursor-pointer ${
                    refundType === item.name && item.isEnable ? "active" : ""
                  } `}
                  key={index}
                  onClick={() => {
                    setRefundType(item.name);
                    setIsSelected(true);
                    setRefundOption(
                      item.id === "1"
                        ? "original_payment_method"
                        : "store_credit"
                    );
                    setIsBtnDisabled(false);
                  }}
                >
                  <div className="choose__card_text">
                    <h2 className="choose__card_title">{item.name}</h2>
                    <p className="choose__text ">{item.desc}</p>
                  </div>

                  <label className="p12__label_rm radio_options">
                    <input
                      className="choose__input"
                      type="radio"
                      name="choose"
                    />
                    <span className="choose__input_circle big "></span>
                  </label>
                </div>
              )
            );
          })}
        </div>
        <div className="row btns">
          <div className="col-6 col-md-4 col-lg-3 col-xl-2 btn__big_screen">
            <button className="btn btn__back back-page" onClick={backHandler}>
              Back
            </button>
          </div>
          <div className="col-12 col-md-4 col-lg-3 col-xl-2">
            <Button
              name={
                isLoader ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Continue"
                )
              }
              onClick={buttonHandler}
              disabled={isBtnDisabled}
            />
          </div>
        </div>
      </div>
    </main>
  );
};
