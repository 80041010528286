import React, { useState, useEffect, useRef } from "react";
import { HeaderText, Button } from "../";
import { useOrderContext } from "../../contexts";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductDetails,
  updateExchangeSettingsDetails,
} from "../../redux/slices";
import {
  productActionTypes,
  exchangeSettingsActionTypes,
} from "../../redux/utils";
import { capitalizeFirstLetter } from "../../Utilities/capitalizeFirstLetter";

export const Return = () => {
  const [isTextArea, setIsTextArea] = useState(false);
  const [reasonText, setReasonText] = useState("");
  const [changeRoute, setChangeRoute] = useState("");
  const [returnReason, setReturnReason] = useState();
  const [reasonID, setReasonID] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const reasonDetailRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);

  const { setIsSelected } = useOrderContext();

  useEffect(() => {
    setReturnReason(productDetailsState.returnReason);
  }, []);

  useEffect(() => {
    if (reasonText === "collect_multiple") {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_MULTIPLE_IMAGES_UPLOAD,
          value: true,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_IMAGE_UPLOAD,
          value: true,
        })
      );
    } else if (reasonText === "collect_one") {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_SINGLE_IMAGE_UPLOAD,
          value: true,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_IMAGE_UPLOAD,
          value: true,
        })
      );
    } else if (reasonText === "" || reasonText === "dont_collect") {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_IMAGE_UPLOAD,
          value: false,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IMAGE_LIST,
          value: null,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_MULTIPLE_IMAGES_UPLOAD,
          value: false,
        })
      );

      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_SINGLE_IMAGE_UPLOAD,
          value: false,
        })
      );
    }

    setChangeRoute("/return");
  }, [reasonText, dispatch]);

  const showOtherReason = (closeTextBox) => {
    if (closeTextBox) {
      reasonDetailRef.current.value = "";
      setIsTextArea(false);
    } else {
      setIsTextArea(true);
    }
  };

  const checkExchangeAndReturn = (item) => {
    const ItemExchangeAllowed = selectedProductDetailsState.itemExchangeAllowed;

    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_ALLOW_EXCHANGE,
        value: item.allow_exchange,
      })
    );

    dispatch(
      updateExchangeSettingsDetails({
        key: exchangeSettingsActionTypes.UPDATE_ALLOW_RETURN,
        value: item.allow_return,
      })
    );

    const AllowExchangeReason = exchangeSettingsState.allowExchange;

    if (ItemExchangeAllowed === "false" || AllowExchangeReason === "false") {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_FINAL_EXCHANGE,
          value: false,
        })
      );
    } else {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_FINAL_EXCHANGE,
          value: true,
        })
      );
    }

    const EnableIncentive = productDetailsState.enableIncentive;
    const ReturnAllowed = selectedProductDetailsState.returnAllowed;
    const AllowReturn = exchangeSettingsState.allowReturn;

    if (
      !selectedProductDetailsState.returnAllowed ||
      !exchangeSettingsState.allowReturn
    ) {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_ENABLE_REFUND,
          value: false,
        })
      );
    } else {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_IS_ENABLE_REFUND,
          value: true,
        })
      );
    }

    if (!EnableIncentive && (!ReturnAllowed || !AllowReturn)) {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_FINAL_INCENTIVE,
          value: false,
        })
      );
    } else {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_FINAL_INCENTIVE,
          value: true,
        })
      );
    }

    dispatch(
      setProductDetails({
        key: productActionTypes.SET_RETURN_REASON_ID,
        value: item.reason.id,
      })
    );
  };

  const buttonHandler = () => {
    dispatch(
      setProductDetails({
        key: productActionTypes.SET_REASON_DETAILS,
        value: reasonDetailRef.current.value,
      })
    );
    setReasonID(null);
    navigate(changeRoute);
  };

  const backHandler = () => {
    window.history.back();
  };

  return (
    <main className="reason">
      <div className="container">
        <div className="row justify-content-center row__title">
          <Link to="/items" className="btn__small_screen">
            <img src="assets/svg/chevron_left.svg" alt="back" />
          </Link>
          <HeaderText text="Select the reason of your return/exchange" />
        </div>
        <div className="row justify-content-center">
          {returnReason?.map((item) => {
            return (
              <div
                className="col-12 col-sm-6 col-lg-5 col-xl-4  "
                key={item.reason.id}
              >
                <label
                  className={`reason__label ${
                    reasonID === item.reason.id && "active"
                  }`}
                  data-location="location1"
                  onClick={() => {
                    setIsSelected(true);
                    setReasonID(item.reason.id);
                    checkExchangeAndReturn(item);
                    setIsBtnDisabled(false);
                  }}
                >
                  {capitalizeFirstLetter(item.reason.name)}
                  <input
                    className="reason__input"
                    type="radio"
                    name="reason"
                    onChange={() => setReasonText(item.picture_collection)}
                  />
                  <span className="reason__input_circle"></span>
                </label>
              </div>
            );
          })}
          <div className={`col-12 col-lg-10 col-xl-12 `}>
            <label
              className=" reason__label other show"
              data-location="location5"
              onClick={(e) => {
                showOtherReason();
              }}
            >
              Reason Details
              {/* Close Icon */}
              <img
                src="assets/svg/close.svg"
                alt="close"
                onClick={(e) => {
                  showOtherReason(true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className={`${
                  isTextArea ? "other__close show" : "other__close"
                }`}
              />
              {/* Text Area */}
              <textarea
                ref={reasonDetailRef}
                maxLength="500"
                className={` ${
                  isTextArea ? "other__textarea show" : "other__textarea"
                }`}
                onClick={(e) => {
                  setIsSelected(e.target.value.length > 0);
                }}
              ></textarea>
            </label>
          </div>
        </div>
        <div className="row btns">
          <div className="col-6 col-md-4 col-lg-3 col-xl-2 btn__big_screen">
            <button
              href="!#"
              className="btn btn__back back_page"
              onClick={backHandler}
            >
              Back
            </button>
          </div>

          <div className="col-12 col-md-4 col-lg-3 col-xl-2">
            <Button
              name={
                isLoader ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Continue"
                )
              }
              onClick={buttonHandler}
              disabled={isBtnDisabled}
            />
          </div>
        </div>
      </div>
    </main>
  );
};
