import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { removeInsignificantZero } from "../../Utilities";
import { useExchangeFetch } from "../../hooks";
import { Button, FullPageLoader } from "..";

export const ExchangeReview = () => {
  const { itemToBeReturnedTags, NoVariantAvailable, isExLoader } =
    useExchangeFetch();
  const navigate = useNavigate();
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);

  /*  if multiple photo upload is allowed navigate to exchange payment else upload photo  */
  const buttonHandler = () => {
    return !exchangeSettingsState.isImageUpload
      ? navigate("/ExchangePayment")
      : navigate("/photo");
  };

  /* navigate to previous page */
  const backHandler = () => {
    window.history.back();
  };

  return (
    <>
      {isExLoader ? (
        <FullPageLoader />
      ) : (
        <main className="p20 choose">
          <div className="container">
            <div className="row justify-content-center row__title">
              <Link to="" onClick={backHandler} className="btn__small_screen">
                <img src="assets/svg/chevron_left.svg" alt="back" />
              </Link>
              <div className="col-12">
                <h1 className="p20__title title text-center">
                  Review Exchange
                </h1>
              </div>
            </div>
            <div className="row justify-content-center p20__row">
              <div className="col-6 col-lg-4 mb-5 mb-lg-0">
                <div className="exchange__card ">
                  <span className="exchange__card_status status__old">old</span>
                  <div className="exchange__picture">
                    <img
                      src={selectedProductDetailsState.selectedProductImage}
                      alt=""
                      className="exchange__img"
                    />
                  </div>
                  <div className="exhange__text">
                    <p className="exchange__card_desc">
                      {exchangeSettingsState.selectedProductName}
                    </p>
                    <div className="exchange__card_info">
                      <p
                        className={`${
                          !selectedProductDetailsState.selectedProductPrice &&
                          "d-none"
                        }`}
                      >
                        Price:{" "}
                        <span className="exchange__card_price">
                          {productDetailsState.currency}
                          {removeInsignificantZero(
                            parseFloat(
                              selectedProductDetailsState.selectedProductPrice
                            )
                          )}
                        </span>{" "}
                      </p>
                      <span className="exchange__card_separator">
                        / {/* formatting the tags in single variable */}
                        {itemToBeReturnedTags
                          ?.substring(0, itemToBeReturnedTags?.length - 1)
                          .replace(/,/g, "/")
                          .split(" ")
                          .join(":")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-4 mb-5 mb-lg-0">
                <div className="exchange__card">
                  <span className="exchange__card_status status__new">new</span>
                  <div className="exchange__picture">
                    <img
                      src={exchangeSettingsState.newItemImage}
                      alt=""
                      className="exchange__img"
                    />
                  </div>
                  <div className="exhange__text">
                    <p className="exchange__card_desc">
                      {exchangeSettingsState.newItemName}
                    </p>
                    <div className="exchange__card_info">
                      <p
                        className={`${
                          !exchangeSettingsState.newItemPrice && "d-none"
                        }`}
                      >
                        Price:{" "}
                        <span className="exchange__card_price">
                          {/* adding currency and removing zero after decimal */}
                          {productDetailsState.currency}
                          {removeInsignificantZero(
                            parseFloat(exchangeSettingsState.newItemPrice)
                          )}
                        </span>{" "}
                      </p>
                      <span
                        className={`exchange__card_separator ${
                          (!exchangeSettingsState.newItemSize ||
                            exchangeSettingsState.newItemSize === "") &&
                          "d-none"
                        }`}
                      >
                        /
                      </span>
                      <p
                        className={`${
                          (!exchangeSettingsState.newItemSize ||
                            exchangeSettingsState.newItemSize === "") &&
                          "d-none"
                        }`}
                      >
                        Size:{" "}
                        <span className="exchange__card_size">
                          {exchangeSettingsState.newItemSize}
                        </span>
                      </p>
                      <span
                        className={`exchange__card_separator ${
                          (!exchangeSettingsState.newItemColor ||
                            exchangeSettingsState.newItemColor === "") &&
                          "d-none"
                        }`}
                      >
                        /
                      </span>
                      <p
                        className={`${
                          (!exchangeSettingsState.newItemColor ||
                            exchangeSettingsState.newItemColor === "") &&
                          "d-none"
                        }`}
                      >
                        Color:{" "}
                        <span className="exchange__card_size">
                          {exchangeSettingsState.newItemColor}
                        </span>
                      </p>
                      <span
                        className={`exchange__card_separator ${
                          (NoVariantAvailable ||
                            exchangeSettingsState.newItemOtherTag ===
                              "Default Title") &&
                          "d-none"
                        }`}
                      >
                        {!exchangeSettingsState.newItemOtherTag ||
                        exchangeSettingsState.newItemOtherTag === ""
                          ? ""
                          : "/"}
                      </span>
                      <p>
                        <span
                          className={`exchange__card_size ${
                            (NoVariantAvailable ||
                              exchangeSettingsState.newItemOtherTag ===
                                "Default Title") &&
                            "d-none"
                          }`}
                        >
                          {exchangeSettingsState.newItemOtherTag}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row btns back-btn-exchange">
              <div className="col-6 col-md-4 col-lg-3 col-xl-2 btn__big_screen">
                <button
                  className="btn btn__back back_page"
                  onClick={backHandler}
                >
                  Back
                </button>
              </div>

              <div className="col-12 col-md-4 col-lg-3 col-xl-2">
                <Button
                  name="Continue"
                  onClick={buttonHandler}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};
