import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppContext } from "../../contexts";
import { lang } from "../../en";
import "./header.css";

export const Header = () => {
  const { logoURL, baseURL } = useAppContext();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container d-flex justify-content-between align-items-center">
          <a
            href={baseURL}
            target="_blank"
            rel="noreferrer"
            className="logo brand-logo-text"
          >
            {settingState.brandLogo && settingState.brandLogo !== "null" ? (
              <img
                src={settingState.brandLogo}
                alt="logo"
                className="logo_img"
              />
            ) : (
              settingState.storeSubDomain
            )}
          </a>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a
                href={settingState.baseURL}
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {lang.homePage.navItemA}
              </a>
            </li>
            <li className="nav-item">
              <a
                href={`${settingState.baseURL}/collections/all`}
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                {lang.homePage.navItemB}
              </a>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <div className="nav-icon" onClick={handleClick}>
              <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
