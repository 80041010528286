export const lang = {
  commonText: {
    continueBtn: "Continue",
    backBtn: "Back",
  },
  homePage: {
    navItemA: "HOME",
    navItemB: "CATALOG",
    heading: "Returns & Exchanges",
    headingDesc:
      "Return/Exchange your product in just a few clicks. Please enter your order number and email address / mobile number to continue.",
    formHeading: "Get Started",
    formInputA: "Order Number",
    formInputB: "Email Address / Mobile Number",
    formCustomError: "Please enter numeric order ID such as 1005",
  },
  itemPage: {
    heading: "Select items you want to return",
  },
  reasonsPage: {
    heading: "Select the reason of your return",
  },
  returnPage: {
    heading: "What would you like to do?",
  },
  uploadImgPage: {
    heading: "Upload an image of the",
  },
  exchangePage: {
    heading: "Exchange option",
    credit: "Available Credit",
  },
  exchangeReviewPage: {
    heading: "Review Exchange",
    newLabel: "NEW",
    oldLabel: "OLD",
  },
  exchangePaymentPage: {
    heading: "Exchange payment",
    itemValue: "item Value",
    pickupFee: "Pickup Fee",
    priceDifference: "Price Difference",
    totalRefund: "Total Refund",
    button: "SUBMIT EXCHANGE",
  },
  footer: {
    copyright: ` Copyright©${
      /\d{4}/.exec(Date())[0]
    } – Saara Inc. All Right Reserved.`,
  },
};
