import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storeSettingsSlice from './slices/store-settings';
import productDetailsSlice from './slices/product';
import selectedProductDetailsSlice from './slices/selected-product';
import exchangeSettingsSlice from './slices/exchange-settings';
import paymentSettingsSlice from './slices/payment-settings';
import refundSettingsSlice from './slices/refund-settings';

const reducers = combineReducers({
  storeSettings: storeSettingsSlice,
  productDetails: productDetailsSlice,
  selectedProductDetails: selectedProductDetailsSlice,
  exchangeSettings: exchangeSettingsSlice,
  paymentSettings: paymentSettingsSlice,
  refundSettings: refundSettingsSlice,
});

const persistReduxKey = 'root';

const persistConfig = {
  key: persistReduxKey,
  storage,
  version: process.env.REACT_APP_REDUX_VERSION,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
