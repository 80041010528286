import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useExchangeFetch } from "../../hooks";
import { removeInsignificantZero } from "../../Utilities";
import { FullPageLoader } from "..";
import { updatePaymentSettings } from "../../redux/slices";
import { paymentSettingTypes } from "../../redux/utils";

export const ExchangePayment = () => {
  const { itemToBeReturnedTags, NoVariantAvailable } = useExchangeFetch();
  const [priceDifference, setPriceDifference] = useState(false);
  const [availableCredit, setAvailableCredit] = useState(0);
  const [reverseShipmentCharges, setReverseShipmentCharges] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [isFullScreenLoader, setIsFullScreenLoader] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/exchange/item/payment_info/`,
          {
            store: settingState.storeUniqueID,
            old_variant_id: selectedProductDetailsState.variantID,
            new_variant_id: exchangeSettingsState.exchangeVariantID,
            order_id: productDetailsState.recentOrderID,
            order_number: productDetailsState.orderNumber,
            email: productDetailsState.customerEmail,
            test_mode: productDetailsState.testMode,
            line_item_id: selectedProductDetailsState.lineItemID,
            quantity: parseInt(selectedProductDetailsState.selectedQuantity)
          }
        );

        if (response?.status === 200) {
          setIsFullScreenLoader(false);
          setAvailableCredit(response?.data?.available_credit);
          setReverseShipmentCharges(response?.data?.reverse_shipment_charges);
          setRefundAmount(response?.data?.refund_amount);
          setDueAmount(response?.data?.due_amount);
          setPriceDifference(response?.data?.price_difference);
        }
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }
    })();
  }, [navigate]);

  const submitExchangeHandler = (e) => {
    (async () => {
      try {
        setIsLoader(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/exchange/item/${selectedProductDetailsState.variantID}/`,
          {
            customer_id: productDetailsState.customerID,
            shop: settingState.storeUniqueID,
            variant_id: selectedProductDetailsState.variantID,
            selected_variant_for_exchange:
              exchangeSettingsState.exchangeVariantID,
            image_list: exchangeSettingsState.imageList,
            primary_reason: productDetailsState.returnReasonID,
            customer_email: productDetailsState.customerEmail,
            test_mode: productDetailsState.testMode,
            order_id: productDetailsState.recentOrderID,
            order_number: productDetailsState.orderNumber,
            line_item_id: selectedProductDetailsState.lineItemID,
            other_reason_desc: productDetailsState.reasonDetails,
            quantity: parseInt(selectedProductDetailsState.selectedQuantity)
          }
        );
        if (response?.status === 200) {
          setIsLoader(false);

          dispatch(
            updatePaymentSettings({
              key: paymentSettingTypes.UPDATE_PAYMENT_REQUIRED,
              value: response.data.payment_required,
            })
          );

          dispatch(
            updatePaymentSettings({
              key: paymentSettingTypes.UPDATE_PAYMENT_LINK,
              value: response.data.payment_link,
            })
          );

          dispatch(
            updatePaymentSettings({
              key: paymentSettingTypes.UPDATE_PAYMENT_ID,
              value: response.data.id,
            })
          );

          navigate("/ExchangeSubmitted");
        }
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }
    })();
  };

  /* navigate to previous page */
  const backHandler = () => {
    window.history.back();
  };

  return (
    <>
      {isFullScreenLoader ? (
        <FullPageLoader />
      ) : (
        <main className="p21 choose">
          <div className="container">
            <div className="row justify-content-center row__title">
              <Link to="" onClick={backHandler} className="btn__small_screen">
                <img src="assets/svg/chevron_left.svg" alt="back" />
              </Link>
              <div className="col-12">
                <h1 className="p21__title title">Exchange payment</h1>
              </div>
            </div>
            <div className="row justify-content-center p21__row">
              <div className="col-12 col-md-8 col-lg-4 order-1 order-lg-0">
                <div className="p21__wrapper exchange__wrapper">
                  <div className="p21__content exchange__content">
                    <ul className="p21__payment_list payment_list">
                      <li className="p21__payment_item payment_item">
                        <span className="p21__payment_property payment_property">
                          Item value:
                        </span>
                        <span className="p21__payment_value payment_value">
                          {productDetailsState.currency}

                          {removeInsignificantZero(
                            parseFloat(exchangeSettingsState.newItemPrice)
                          )}
                        </span>
                      </li>
                      <li
                        className={`p21__payment_item payment_item ${
                          reverseShipmentCharges > 0 ? "" : "d-none"
                        }`}
                      >
                        <span className="p21__payment_property payment_property">
                          Pickup Fee:
                        </span>
                        <span className="p21__payment_value payment_value">
                          {`${
                            productDetailsState.currency
                          }${removeInsignificantZero(
                            parseFloat(reverseShipmentCharges)
                          )}`}
                        </span>
                      </li>

                      <li
                        className={`p21__payment_item payment_item ${
                          priceDifference ? "" : "d-none"
                        }`}
                      >
                        <span className="p21__payment_property payment_property">
                          Price Difference:
                        </span>
                        <span className="p21__payment_value payment_value">
                          {`${
                            productDetailsState.currency
                          }${removeInsignificantZero(
                            parseFloat(priceDifference)
                          )}`}
                        </span>
                      </li>
                      <li className="p21__payment_line"></li>
                      <li className="p21__payment_item payment_item">
                        <span className="p21__payment_property payment_property">
                          {refundAmount > 0 ? "Total Refund: " : "Total Due: "}
                        </span>
                        <span className="p21__payment_value payment_value">
                          {productDetailsState.currency}
                          {removeInsignificantZero(
                            parseFloat(
                              refundAmount > 0 ? refundAmount : dueAmount
                            )
                          )}
                        </span>
                      </li>
                    </ul>

                    <div
                      className={`d-none p21__methods_pay ${
                        refundAmount > 0 ? "" : "d-none"
                      }`}
                    >
                      <div className="p21__methods_card">
                        <img
                          src="assets/svg/cards.svg"
                          width="32"
                          height="24"
                          alt="card"
                        />
                        <p className="p21__methods_desc">Card</p>
                      </div>
                      <div className="p21__methods_card">
                        <img
                          src="assets/svg/netbanking.svg"
                          width="32"
                          height="24"
                          alt="net banking"
                        />
                        <p className="p21__methods_desc">Netbanking </p>
                      </div>
                      <div className="p21__methods_card">
                        <img
                          src="assets/svg/upi-qr.svg"
                          width="32"
                          height="24"
                          alt="net banking"
                        />
                        <p className="p21__methods_desc">Upi/Qr</p>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center align-items-center gap-10">
                    <div className="btn__big_screen">
                      <button
                        type="button"
                        className="btn btn__continue button continue-payment"
                        onClick={(e) => {
                          submitExchangeHandler(e);
                        }}
                      >
                        {isLoader ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : refundAmount > 0 || dueAmount === 0 ? (
                          "SUBMIT EXCHANGE"
                        ) : (
                          "CONTINUE"
                        )}
                      </button>
                      <br /> <br />
                      <button
                        className="btn btn__back back_page exchange-payment-back"
                        onClick={backHandler}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-4 mb-5 mb-lg-0">
                <div className="exchange__card ">
                  <span className="exchange__card_status status__old">old</span>
                  <div className="exchange__picture">
                    <img
                      src={selectedProductDetailsState.selectedProductImage}
                      alt=""
                      className="exchange__img"
                    />
                  </div>
                  <div className="exhange__text">
                    <p className="exchange__card_desc">
                      {exchangeSettingsState.selectedProductName}
                    </p>
                    <div className="exchange__card_info">
                      <p
                        className={`${
                          !selectedProductDetailsState.selectedProductPrice &&
                          "d-none"
                        }`}
                      >
                        Price:{" "}
                        <span className="exchange__card_price">
                          {productDetailsState.currency}
                          {removeInsignificantZero(
                            parseFloat(
                              selectedProductDetailsState.selectedProductPrice
                            )
                          )}
                        </span>{" "}
                      </p>
                      <span className="exchange__card_separator">
                        {itemToBeReturnedTags
                          ?.substring(0, itemToBeReturnedTags?.length - 1)
                          .replace(/,/g, "/")
                          .split(" ")
                          .join(":")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-4 mb-5 mb-lg-0">
                <div className="exchange__card">
                  <span className="exchange__card_status status__new">new</span>
                  <div className="exchange__picture">
                    <img
                      src={exchangeSettingsState.newItemImage}
                      alt=""
                      className="exchange__img"
                    />
                  </div>
                  <div className="exhange__text">
                    <p className="exchange__card_desc">
                      {exchangeSettingsState.newItemName}
                    </p>
                    <div className="exchange__card_info">
                      <p
                        className={`${
                          !exchangeSettingsState.newItemPrice && "d-none"
                        }`}
                      >
                        Price:{" "}
                        <span className="exchange__card_price">
                          {productDetailsState.currency}
                          {removeInsignificantZero(
                            parseFloat(exchangeSettingsState.newItemPrice)
                          )}
                        </span>{" "}
                      </p>
                      <span
                        className={`exchange__card_separator ${
                          (!exchangeSettingsState.newItemSize ||
                            exchangeSettingsState.newItemSize === "") &&
                          "d-none"
                        }`}
                      >
                        /
                      </span>
                      <p
                        className={`${
                          (!exchangeSettingsState.newItemSize ||
                            exchangeSettingsState.newItemSize === "") &&
                          "d-none"
                        }`}
                      >
                        Size:{" "}
                        <span className="exchange__card_size">
                          {exchangeSettingsState.newItemSize}
                        </span>
                      </p>
                      <span
                        className={`exchange__card_separator ${
                          (!exchangeSettingsState.newItemColor ||
                            exchangeSettingsState.newItemColor === "") &&
                          "d-none"
                        }`}
                      >
                        /
                      </span>
                      <p
                        className={`${
                          (!exchangeSettingsState.newItemColor ||
                            exchangeSettingsState.newItemColor === "") &&
                          "d-none"
                        }`}
                      >
                        Color:{" "}
                        <span className="exchange__card_size">
                          {exchangeSettingsState.newItemColor}
                        </span>
                      </p>
                      <span
                        className={`exchange__card_separator ${
                          (NoVariantAvailable ||
                            exchangeSettingsState.newItemOtherTag ===
                              "Default Title") &&
                          "d-none"
                        }`}
                      >
                        {!exchangeSettingsState.newItemOtherTag ||
                        exchangeSettingsState.newItemOtherTag === ""
                          ? ""
                          : "/"}
                      </span>
                      <p>
                        <span
                          className={`exchange__card_size ${
                            (NoVariantAvailable ||
                              exchangeSettingsState.newItemOtherTag ===
                                "Default Title") &&
                            "d-none"
                          }`}
                        >
                          {exchangeSettingsState.newItemOtherTag}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};
