import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
export const PathNameContext = createContext();

export const PathNameProvider = ({ children }) => {
  const location = useLocation();
  const pathURL = location.pathname;
  const params = new URLSearchParams(useLocation().search);

  return (
    <PathNameContext.Provider
      value={{
        pathURL,
        params,
      }}
    >
      {children}
    </PathNameContext.Provider>
  );
};
export const usePathNameContext = () => {
  return useContext(PathNameContext);
};
