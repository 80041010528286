import React, { useState, useEffect } from 'react';
import { HeaderText, Button } from '../';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateExchangeSettingsDetails,
  updateRefundSettingsDetails,
} from '../../redux/slices';
import {
  exchangeSettingsActionTypes,
  refundSettingsActionTypes,
} from '../../redux/utils';
import { useOrderContext } from '../../contexts';
import './returnOptions.css';

export const ReturnOptions = () => {
  const [option, setOption] = useState();
  const { setIsSelected, setRecentOrderID, setVariantID } = useOrderContext();
  const [changeRoute, setChangeRoute] = useState('');
  const [isExchange, setIsExchange] = useState(true);
  const [isNeighborhoodSell, setIsNeighborhoodSell] = useState(true);
  const [isGetRefund, setIsGetRefund] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const navigate = useNavigate();
  const [isRefund, setIsRefund] = useState(true);
  const [incentiveMsg, setIncentiveMsg] = useState(true);

  const dispatch = useDispatch();
  const settingState = useSelector((state) => state.storeSettings);
  const productDetailsState = useSelector((state) => state.productDetails);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );
  const exchangeSettingsState = useSelector((state) => state.exchangeSettings);
  const storeConfigState = useSelector((state) => state.storeSettings);

  const returnOptions = [
    {
      id: '1',
      option: 'Exchange Item',
      desc: [
        {
          id: '1',
          iconURL: '',
          info: `${
            productDetailsState.collectPriceDifferencePercent
              ? `Exchange with items upto ${productDetailsState.collectPriceDifferencePercentValue}% higher than the original item`
              : ''
          }`,
        },
      ],
      isShow: productDetailsState.exchangeItem,
      isAllowed: exchangeSettingsState.finalExchange,
      isRefund: true,
    },
    {
      id: '2',
      option: 'Sell it to neighbour at discount',
      desc: [
        {
          id: '1',
          iconURL: 'assets/svg/floppy-disk.svg',
          info: 'Save 2 kg of CO2 emission',
        },
        {
          id: '2',
          iconURL: 'assets/svg/cards2.svg',
          info: `Get ${productDetailsState.currency}5 as store credit`,
        },
        {
          id: '3',
          iconURL: 'assets/svg/return.svg',
          info: 'Get 3 days of return extension',
        },
      ],
      isShow: 'false',
      isAllowed: 'true',
      isRefund: 'true',
    },
    {
      id: '3',
      option: 'Return Item',
      desc: [{ id: '1', iconURL: '', info: '' }],
      isShow: productDetailsState.getRefund,
      isAllowed: exchangeSettingsState.finalIncentive,
      isRefund: isRefund,
    },
  ];

  useEffect(() => {
    if (option === '1') {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NAVIGATE_BACK,
          value: '/exchange',
        })
      );

      setChangeRoute('/exchange');
    } else if (option === '2') {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NAVIGATE_BACK,
          value: null,
        })
      );
      setChangeRoute('/neighbour');
    } else {
      dispatch(
        updateExchangeSettingsDetails({
          key: exchangeSettingsActionTypes.UPDATE_NAVIGATE_BACK,
          value: '/refund',
        })
      );

      if (!exchangeSettingsState.isImageUpload) {
        setChangeRoute('/refund');
      } else {
        setChangeRoute('/photo');
      }
    }
  }, [option]);

  const buttonHandler = () => {
    (async () => {
      try {
        setIsLoader(true);
        const RecentOrderID = productDetailsState.recentOrderID;
        const VariantID = selectedProductDetailsState.variantID;
        setIsExchange(productDetailsState.exchangeItem);
        setIsNeighborhoodSell(productDetailsState.neighborhoodCommerce);
        setIsGetRefund(productDetailsState.getRefund);
        setRecentOrderID(RecentOrderID);
        setVariantID(VariantID);

        if (option === '1') {
          setIsBtnDisabled(false);
          setIsLoader(false);

          dispatch(
            updateExchangeSettingsDetails({
              key: exchangeSettingsActionTypes.UPDATE_NAVIGATE_FORWARD,
              value: changeRoute,
            })
          );

          navigate(changeRoute);
        }
        if (option === '3') {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/fetch-incentive/`,
            {
              order_id: RecentOrderID,
              shop: settingState.storeUniqueID,
              variant_id: VariantID,
              test_mode: productDetailsState.testMode,
              line_item_id: selectedProductDetailsState.lineItemID,
              quantity: parseInt(selectedProductDetailsState.selectedQuantity),
            }
          );

          if (response.data) {
            dispatch(
              updateRefundSettingsDetails({
                key: refundSettingsActionTypes.UPDATE_INCENTIVE,
                value: response?.data?.incentive,
              })
            );

            setIsLoader(false);
            if (response?.data?.incentive || response?.data?.incentive === 0) {
              setIsRefund(true);

              navigate(changeRoute);

              dispatch(
                updateExchangeSettingsDetails({
                  key: exchangeSettingsActionTypes.UPDATE_NAVIGATE_FORWARD,
                  value: changeRoute,
                })
              );
              setIsBtnDisabled(false);
            }
            if (response?.data?.message) {
              setIsRefund(false);
              setIncentiveMsg(response?.data?.message);
              setIsBtnDisabled(true);

              dispatch(
                updateRefundSettingsDetails({
                  key: refundSettingsActionTypes.UPDATE_INCENTIVE,
                  value: response?.data?.message,
                })
              );
            }
          }
        }
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate('/internal-server-error');
        }
      }
    })();
  };

  /* Navigate to Previous Page */
  const backHandler = () => {
    window.history.back();
  };

  return (
    <main className="p6 choose">
      <div className="container">
        <div className="row justify-content-center row__title">
          <Link to="" onClick={backHandler} className="btn__small_screen">
            <img src="assets/svg/chevron_left.svg" alt="back" />
          </Link>
          <HeaderText text="What would you like to do?" />
        </div>
        <div className="justify-content-center p6__row  cursor-pointer">
          {returnOptions.map((items) => {
            return (
              <div
                className={` ${items.isShow === 'false' ? 'd-none' : ''} ${
                  option === items.id &&
                  items.isAllowed !== 'false' &&
                  items.isRefund
                    ? 'choose__card active'
                    : 'choose__card'
                }`}
                data-location="location1"
                key={items.id}
                onClick={() => {
                  setOption(items.id);
                  setIsSelected(true);
                  setIsBtnDisabled(items.isAllowed === 'false' ? true : false);
                }}
              >
                <div
                  className="choose_card_text"
                  style={{ textAlign: 'center', width: '100%' }}
                >
                  <h2 className="choose__card_title">{items.option}</h2>
                  <ul className="choose__list">
                    {items.desc.map((item) => {
                      return (
                        <li className="choose__item" key={item.id}>
                          <span className="choose__sign">
                            {item.iconURL && (
                              <img
                                src={item.iconURL}
                                className="choose__icon"
                                alt="coins"
                              />
                            )}
                          </span>
                          <span className="choose__item_text">{item.info}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="error-main">
                  {items.isAllowed === 'false' && (
                    <span className="info__error">
                      {storeConfigState.returnPolicyURL ? (
                        <a
                          className="info__error_policy"
                          href={storeConfigState.returnPolicyURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Not allowed as per store policies.
                        </a>
                      ) : (
                        'Not allowed as per store policies.'
                      )}
                    </span>
                  )}
                  {items.isRefund === false && (
                    <span className="info__error d-flex justify-content-center align-items-center">
                      {incentiveMsg}
                    </span>
                  )}
                </div>
                <label className="p6__label">
                  <input className="choose__input" type="radio" name="choose" />
                  <span className="choose__input_circle big"></span>
                </label>
              </div>
            );
          })}
        </div>
        <div className="row btns">
          <div className="col-6 col-md-4 col-lg-3 col-xl-2 btn__big_screen">
            <button className="btn btn__back back-page" onClick={backHandler}>
              Back
            </button>
          </div>

          <div className="col-12 col-md-4 col-lg-3 col-xl-2">
            <Button
              name={
                isLoader ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  'Continue'
                )
              }
              onClick={buttonHandler}
              disabled={isBtnDisabled}
            />
          </div>
        </div>
      </div>
    </main>
  );
};
