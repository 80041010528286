import React from 'react';
import { Order } from '../Components';

export const Items = () => {
  return (
    <div>
      <Order />
    </div>
  );
};
