import { createSlice } from "@reduxjs/toolkit";
import { productActionTypes } from "../utils";

const productDetailsSlice = createSlice({
  name: "productDetails",

  initialState: {
    otherItemProductDetailsURL: null,
    customerID: null,
    customerEmail: null,
    orderNumber: null,
    exchangeItem: null,
    enableIncentive: null,
    paymentMethod: null,
    testMode: true,
    returnReason: {},
    returnReasonID: null,
    recentOrderID: null,
    collectPriceDifferencePercent: false,
    collectPriceDifferencePercentValue: null,
    originalPaymentMethodRefundAvailable: true,
    enableStoreCreditRefund: true,
    askAccountInformation: true,
    chargeReverseShipmentExchange: false,
    reverseShipmentFee: null,
    collectPriceDifference: false,
    collectPriceDifferenceAmount: null,
    currency: "₹",
    neighborhoodCommerce: false,
    getRefund: true,
    isShowRefund: true,
    orderInfo: null,
    lineItemID: null,
    reasonDetails: null,
  },

  reducers: {
    setProductDetails: (state, action) => {
      const { key, value } = action.payload;

      switch (key) {
        case productActionTypes.SET_OTHER_ITEM_PRODUCT_DETAILS_URL:
          state.otherItemProductDetailsURL = value;
          break;
        case productActionTypes.SET_CUSTOMER_ID:
          state.customerID = value;
          break;
        case productActionTypes.SET_CUSTOMER_EMAIL:
          state.customerEmail = value;
          break;
        case productActionTypes.SET_ORDER_NUMBER:
          state.orderNumber = value;
          break;
        case productActionTypes.SET_EXCHANGE_ITEM:
          state.exchangeItem = value;
          break;
        case productActionTypes.SET_ENABLE_INCENTIVE:
          state.enableIncentive = value;
          break;
        case productActionTypes.SET_PAYMENT_METHOD:
          state.paymentMethod = value;
          break;
        case productActionTypes.SET_TEST_MODE:
          state.testMode = value;
          break;
        case productActionTypes.SET_RETURN_REASON:
          state.returnReason = value;
          break;
        case productActionTypes.SET_RETURN_REASON_ID:
          state.returnReasonID = value;
          break;
        case productActionTypes.SET_RECENT_ORDER_ID:
          state.recentOrderID = value;
          break;
        case productActionTypes.SET_COLLECT_PRICE_DIFFERENCE_PERCENT:
          state.collectPriceDifferencePercent = value;
          break;
        case productActionTypes.SET_COLLECT_PRICE_DIFFERENCE_PERCENT_VALUE:
          state.collectPriceDifferencePercentValue = value;
          break;
        case productActionTypes.SET_ORIGINAL_PAYMENT_METHOD_REFUND_AVAILABLE:
          state.originalPaymentMethodRefundAvailable = value;
          break;
        case productActionTypes.SET_ENABLESTORE_CREDIT_REFUND:
          state.enableStoreCreditRefund = value;
          break;
        case productActionTypes.SET_ASK_ACCOUNT_INFORMATION:
          state.askAccountInformation = value;
          break;
        case productActionTypes.SET_CHARGE_REVERSE_SHIPMENT_EXCHANGE:
          state.chargeReverseShipmentExchange = value;
          break;
        case productActionTypes.SET_REVERSE_SHIPMENT_FEE:
          state.reverseShipmentFee = value;
          break;
        case productActionTypes.SET_COLLECT_PRICE_DIFFERENCE:
          state.collectPriceDifference = value;
          break;
        case productActionTypes.SET_COLLECT_PRICE_DIFFERENCE_AMOUNT:
          state.collectPriceDifferenceAmount = value;
          break;
        case productActionTypes.SET_CURRENCY:
          state.currency = value;
          break;
        case productActionTypes.SET_NEIGHBORHOOD_COMMERCE:
          state.neighborhoodCommerce = value;
          break;
        case productActionTypes.SET_GET_REFUND:
          state.getRefund = value;
          break;
        case productActionTypes.SET_IS_SHOW_REFUND:
          state.isShowRefund = value;
          break;
        case productActionTypes.SET_ORDER_INFO:
          state.orderInfo = value;
          break;
        case productActionTypes.SET_REASON_DETAILS:
          state.reasonDetails = value;
          break;
        default:
      }
    },
  },
});

// Export the slice
export { productDetailsSlice };

// Export the actions
export const { setProductDetails } = productDetailsSlice.actions;

// Export the reducer
export default productDetailsSlice.reducer;
