import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from './routing/routes';
import { Header, Footer, Banner } from './Components';
import { useApp, usePathName } from './hooks';
import FullStory from 'react-fullstory';
import { FullStoryAPI } from 'react-fullstory';
import { PageNotFound } from './pages';
import { useSelector } from 'react-redux';
import insp, { install } from "inspectlet-es";
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';

const App = () => {
  const { response } = useApp();
  const [fetchUser, setFetchUser] = useState(0);
  const [user, setUser] = useState({});
  const [order, setOrder] = useState({});
  const [fetchItems, setFetchItems] = useState();
  const [isActiveInstall, setIsActiveInstall] = useState(false);
  const [orderID, setOrderID] = useState(0);
  const ORG_ID = '16MZJ0';
  const { pathURL } = usePathName();
  const [isShowHeaderFooter, setIsShowHeaderFooter] = useState(true);

  const settingState = useSelector((state) => state.storeSettings);

  /* Inspectlet Code */
  process.env.REACT_APP_ENABLE_INSPECTLET &&
    install(process.env.REACT_APP_INSPECTLET_WID);
  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_INSPECTLET) {
      insp(["identify", settingState.storeUniqueID]);
      window.__insp.push(["identify", settingState.storeUniqueID]);
    }
  });

  useEffect(() => {
    const bodyStyles = document.body.style;

    bodyStyles.setProperty('--blue', settingState.primaryColor);
    bodyStyles.setProperty('--purple', settingState.secondaryColor);

    if (JSON.parse(settingState.customFont)) {
      bodyStyles.setProperty(
        '--f700-primary',
        JSON.parse(settingState.customFont) + '-f700'
      );
      bodyStyles.setProperty(
        '--f400-secondary',
        JSON.parse(settingState.customFont) + '-f400'
      );
      bodyStyles.setProperty(
        '--f500-secondary',
        JSON.parse(settingState.customFont) + '-f500'
      );
      bodyStyles.setProperty(
        '--f600-secondary',
        JSON.parse(settingState.customFont) + '-f600'
      );
      bodyStyles.setProperty(
        '--f700-secondary',
        JSON.parse(settingState.customFont) + '-f700'
      );
      bodyStyles.setProperty(
        '--f800-secondary',
        JSON.parse(settingState.customFont) + '-f800'
      );
      bodyStyles.setProperty(
        '--f900-secondary',
        JSON.parse(settingState.customFont) + '-f900'
      );
      bodyStyles.setProperty(
        '--f700-btn',
        JSON.parse(settingState.customFont) + '-f700'
      );
      bodyStyles.setProperty(
        '--f700-photo-title',
        JSON.parse(settingState.customFont) + '-f700'
      );
      bodyStyles.setProperty(
        '--f400-nav',
        JSON.parse(settingState.customFont) + '-f400'
      );
      bodyStyles.setProperty(
        '--f500-nav',
        JSON.parse(settingState.customFont) + '-f500'
      );
      bodyStyles.setProperty(
        '--f700-nav',
        JSON.parse(settingState.customFont) + '-f700'
      );
    }
  }, []);

  FullStoryAPI('identify', settingState.storeSubDomain, {
    displayName: settingState.storeSubDomain,
  });

  useEffect(() => {
    response?.app?.map((item) => {
      if (item.id === parseInt(fetchUser)) {
        setUser(item);
        if (item.isInstall && item.isActive) {
          setIsActiveInstall(true);
        } else {
          setIsActiveInstall(false);
        }
      }

      return null;
    });

    response?.orderInfo?.map((item) => {
      if (item.id === parseInt(fetchUser)) {
        return setOrder(item);
      }
      return null;
    });

    response?.ordersDetail?.map((item) => {
      if (item.orderID === orderID.toString()) {
        setFetchItems(item);
      } else {
      }
      return null;
    });
  }, [fetchUser, isActiveInstall, orderID, response]);

  useEffect(() => {
    if (
      pathURL?.toLowerCase() === '/' ||
      pathURL?.toLowerCase() === '/home' ||
      pathURL?.toLowerCase() === '/items' ||
      pathURL?.toLowerCase() === '/reason' ||
      pathURL?.toLowerCase() === '/photo' ||
      pathURL?.toLowerCase() === '/return' ||
      pathURL?.toLowerCase() === '/exchange' ||
      pathURL?.toLowerCase() === '/neighbour' ||
      pathURL?.toLowerCase() === '/refund' ||
      pathURL?.toLowerCase() === '/resell' ||
      pathURL?.toLowerCase() === '/thankyou' ||
      pathURL?.toLowerCase() === '/carrier' ||
      pathURL?.toLowerCase() === '/requestsubmitted' ||
      pathURL?.toLowerCase() === '/refundlast' ||
      pathURL?.toLowerCase() === '/exchangepayment' ||
      pathURL?.toLowerCase() === '/exchangesubmitted' ||
      pathURL?.toLowerCase() === '/printshippinglabel' ||
      pathURL?.toLowerCase() === '/exchangeoptions' ||
      pathURL?.toLowerCase() === '/refundoptions' ||
      pathURL?.toLowerCase() === '/exchangereview' ||
      pathURL?.toLowerCase() === '/cod' ||
      pathURL?.toLowerCase() === '/otheritems' ||
      pathURL?.toLowerCase() === '/exchangewithotheritems'
    ) {
      setIsShowHeaderFooter(true);
    } else {
      setIsShowHeaderFooter(false);
    }
  }, [pathURL]);

  return (
    <div>
      <FullStory org={ORG_ID} />
      {settingState.isJaneDoeTestMode === 'true' && <Banner />}
      {isShowHeaderFooter && <Header />}
      <Routes>
        {routes.map((route, key) => (
          <Route path={route.path} element={<route.component />} key={key} />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {isShowHeaderFooter && <Footer />}
    </div>
  );
};

export default App;
