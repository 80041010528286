import { createSlice } from "@reduxjs/toolkit";
import { selectedProductActionTypes } from "../utils";

const selectedProductDetailsSlice = createSlice({
  name: "selectedProductDetails",

  initialState: {
    variantID: null,
    itemExchangeAllowed: null,
    returnAllowed: null,
    selectedProductImage: null,
    selectedProductPrice: null,
    lineItemID: null,
    selectedQuantity: null,
  },

  reducers: {
    updateSelectedProductDetails: (state, action) => {
      const { key, value } = action.payload;

      switch (key) {
        case selectedProductActionTypes.SET_VARIANT_ID:
          state.variantID = value;
          break;
        case selectedProductActionTypes.SET_ITEM_EXCHANGE_ALLOWED:
          state.itemExchangeAllowed = value;
          break;
        case selectedProductActionTypes.SET_RETURN_ALLOWED:
          state.returnAllowed = value;
          break;
        case selectedProductActionTypes.SET_SELECTED_PRODUCT_IMAGE:
          state.selectedProductImage = value;
          break;
        case selectedProductActionTypes.SET_SELECTED_PRODUCT_PRICE:
          state.selectedProductPrice = value;
          break;
        case selectedProductActionTypes.SET_LINE_ITEM_ID:
          state.lineItemID = value;
          break;
        case selectedProductActionTypes.SET_SELECTED_ITEM_QUANTITY:
          state.selectedQuantity = value;
          break;
        default:
      }
    },
  },
});

// Export the slice
export { selectedProductDetailsSlice };

// Export the actions
export const { updateSelectedProductDetails } =
  selectedProductDetailsSlice.actions;

// Export the reducer
export default selectedProductDetailsSlice.reducer;
