import React, { useEffect, useState } from 'react';
import { useOrderContext } from '../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderText, Button } from '../';
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateSetting,
  updateSelectedProductDetails,
} from '../../redux/slices';
import {
  storeSettingTypes,
  selectedProductActionTypes,
} from '../../redux/utils';
import './items.css';

export const ItemGrid = () => {
  const { setOrderInfo, setIsSelected, setVariantID } = useOrderContext();
  const [selectReturnItem, setSelectReturnItem] = useState();
  const [rangeListForQuantityOptions, setRangeListForQuantityOptions] =
    useState([]);
  const history = createBrowserHistory();
  const navigate = useNavigate();
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [selectedProductQty, setSelectedProductQty] = useState(1);
  const [showQty, setShowQty] = useState(false);
  const dispatch = useDispatch();
  const productDetailsState = useSelector((state) => state.productDetails);
  const storeConfigState = useSelector((state) => state.storeSettings);
  const selectedProductDetailsState = useSelector(
    (state) => state.selectedProductDetails
  );

  /* setting the default value for selected product */
  const selectItemHandler = (item) => {
    dispatch(
      updateSelectedProductDetails({
        key: selectedProductActionTypes.SET_VARIANT_ID,
        value: item.variant_id,
      })
    );

    dispatch(
      updateSelectedProductDetails({
        key: selectedProductActionTypes.SET_ITEM_EXCHANGE_ALLOWED,
        value: item.exchange_allowed,
      })
    );

    dispatch(
      updateSelectedProductDetails({
        key: selectedProductActionTypes.SET_RETURN_ALLOWED,
        value: item.return_allowed,
      })
    );

    dispatch(
      updateSelectedProductDetails({
        key: selectedProductActionTypes.SET_SELECTED_PRODUCT_IMAGE,
        value: item.product_image,
      })
    );

    dispatch(
      updateSelectedProductDetails({
        key: selectedProductActionTypes.SET_SELECTED_PRODUCT_PRICE,
        value: item.price,
      })
    );

    dispatch(
      updateSelectedProductDetails({
        key: selectedProductActionTypes.SET_LINE_ITEM_ID,
        value: item.line_item_id,
      })
    );

    //TODO:UPdate the below code when the API is ready for item.quantity.

    //If item.quantity>1
    if (item.quantity > 1) {
      generateQuantityOptions(item?.quantity);
      setShowQty(true);
    } else {
      generateQuantityOptions(1);
      setShowQty(false);
    }

    //On card select set item quantity to 1
    dispatch(
      updateSelectedProductDetails({
        key: selectedProductActionTypes.SET_SELECTED_ITEM_QUANTITY,
        value: '1',
      })
    );

    setVariantID(item.variant_id);

    /* Continue button enable/disable */
    if (!item.exchange_allowed && !item.return_allowed) {
      setIsBtnDisabled(true);
    } else {
      if (selectReturnItem === item.line_item_id) {
        setIsBtnDisabled(true);
        setSelectReturnItem(null);
      } else {
        setSelectReturnItem(item.line_item_id);
        setIsBtnDisabled(false);
      }
    }
  };

  const generateQuantityOptions = (quantity) => {
    let quantityOptions = [];
    for (let i = 1; i <= quantity; i++) {
      quantityOptions.push(i);
    }

    setRangeListForQuantityOptions(quantityOptions);
  };

  const handleSelectQuantity = (event) => {
    dispatch(
      updateSelectedProductDetails({
        key: selectedProductActionTypes.SET_SELECTED_ITEM_QUANTITY,
        value: event.target.value,
      })
    );
  };

  /*Setting the selected value */
  useEffect(() => {
    selectReturnItem ? setIsSelected(true) : setIsSelected(false);
  }, [selectReturnItem]);

  /*Setting the order info */
  useEffect(() => {
    setOrderInfo(productDetailsState.getRefund);
  }, []);

  /*clearing the  selected item and navigate to reason */
  const buttonHandler = () => {
    setSelectReturnItem(null);
    navigate('/reason');
  };

  /* navigate to previous page */
  const backHandler = () => {
    window.history.back();
  };

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        navigate('/');
      }

      dispatch(
        updateSetting({
          key: storeSettingTypes.UPDATE_IS_JOHN_DOE_TEST_MODE,
          value: false,
        })
      );
    });
  }, []);

  return (
    <main className="items">
      <div className="container">
        <div className="row  row__title justify-content-center ">
          <Link to="/" className="btn__small_screen">
            <img src="assets/svg/chevron_left.svg" alt="back" />
          </Link>
          <HeaderText text="Select items you want to return/exchange" />
        </div>
        <div className="items__content ">
          <div className="items__left ">
            <div className="items__left_wrapper justify-content-center ">
              {productDetailsState.orderInfo.map((item) => {
                return (
                  <div
                    className={` items__card cardX show   ${
                      selectReturnItem === item.line_item_id && 'active'
                    } `}
                    key={item.variant_id}
                    onClick={() => {
                      selectItemHandler(item);
                    }}
                  >
                    <span className="card__target">
                      <svg className="card__icon">
                        <use xlinkHref="assets/svg/sprite.svg#choose"></use>
                      </svg>
                    </span>
                    <div className="card__pic">
                      <img
                        src={item.product_image}
                        alt={item.product_name}
                        className="card__img"
                      />
                    </div>
                    <div className="card__info">
                      <h2 className="card__title">
                        {item.product_name}
                        <span className="card__br">{item.tags}</span>
                      </h2>
                      <p className="card__new_price">
                        {productDetailsState.currency}
                        {item.price}
                      </p>
                      {!item.exchange_allowed && !item.return_allowed && (
                        <span className="info__error error-items">
                          {storeConfigState.returnPolicyURL ? (
                            <a
                              className="info__error_policy"
                              href={storeConfigState.returnPolicyURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Not allowed as per store policies.
                            </a>
                          ) : (
                            'Not allowed as per store policies.'
                          )}
                        </span>
                      )}
                    </div>
                    {rangeListForQuantityOptions && (
                      <div
                        className="card__select"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {showQty && selectReturnItem === item.line_item_id && (
                          <select
                            className="card__select__container"
                            onChange={(e) => {
                              handleSelectQuantity(e);
                              e.stopPropagation();
                            }}
                          >
                            {rangeListForQuantityOptions.map(
                              (quantity, key) => {
                                return (
                                  <option
                                    key={key}
                                    className="card__select__container__item"
                                    defaultValue={quantity}
                                    selected={
                                      parseInt(
                                        selectedProductDetailsState.selectedQuantity
                                      ) === quantity
                                    }
                                  >
                                    {quantity}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="row btns">
          <div className="col-6 col-md-4 col-lg-3 col-xl-2 btn__big_screen">
            <button className="btn btn__back back_page" onClick={backHandler}>
              Back
            </button>
          </div>

          <div className="col-12 col-md-4 col-lg-3 col-xl-2">
            <Button
              name="Continue"
              onClick={buttonHandler}
              disabled={isBtnDisabled}
            />
          </div>
        </div>
      </div>
    </main>
  );
};
