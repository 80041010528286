import { createContext, useContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [response, setResponse] = useState([]);
  const [isShowExchangeNext, setIsShowExchangeNext] = useState(false);
  const [isShowFinalPayment, setIsShowFinalPayment] = useState(false);
  const [logoURL, setLogoURL] = useState();
  const [baseURL, setBaseURL] = useState();
  const [path, setPath] = useState("/");
  const [reasonID, setReasonID] = useState();
  const [isAutoFill, setIsAutoFill] = useState(false);
  const [currentItems, setCurrentItems] = useState(null);
  const [isShowFooter, setIsShowFooter] = useState("");
  const [copyRightSectionText, setCopyRightSectionText] = useState("");
  const [storeName, setStoreName] = useState("");
  return (
    <AppContext.Provider
      value={{
        response,
        setResponse,
        path,
        setPath,
        isShowExchangeNext,
        setIsShowExchangeNext,
        isShowFinalPayment,
        setIsShowFinalPayment,
        logoURL,
        setLogoURL,
        baseURL,
        setBaseURL,
        reasonID,
        setReasonID,
        isAutoFill,
        setIsAutoFill,
        currentItems,
        setCurrentItems,
        isShowFooter,
        setIsShowFooter,
        copyRightSectionText,
        setCopyRightSectionText,
        storeName,
        setStoreName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = () => {
  return useContext(AppContext);
};
