import { useEffect } from 'react';
import { useAppContext } from '../contexts';
import { useNavigate } from 'react-router-dom';

export const useApp = () => {
  const { response, setResponse } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
      } catch (error) {
        if (error.response.status === 500) {
          navigate('/internal-server-error');
        }
      }
    })();
  }, []);

  return { response };
};
