import React from 'react';
import { useSelector } from 'react-redux';
import './footer.css';

export const Footer = () => {
  const settingState = useSelector((state) => state.storeSettings);

  return (
    <footer className="footer-custom">
      Return Portal @&nbsp;
      <span className="footer-store-text">{settingState.storeSubDomain}</span>
    </footer>
  );
};
