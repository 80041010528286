import React from 'react';
import { useSelector } from 'react-redux';
import './e404.css';

export const E404 = () => {
  const settingState = useSelector((state) => state.storeSettings);

  return (
    <div className="container404">
      <div className="seventy404">
        <div className="content404">
          <h1 className="h1404" style={{ fontWeight: 'bold' }}>
            404, page not
            <br />
            found
            <br />
          </h1>
          <h5 className="h5404">
            instead found eco-friendly and
            <br /> painless returns.
          </h5>
          {!settingState.is404 && (
            <a href="/">
              <button className="pink-btn404">
                <span>HOME</span>
              </button>
            </a>
          )}
        </div>
        <img
          className="carbon-img404"
          src="assets/404_not_found.jpg"
          alt="404"
        />
      </div>
      <div className="thirty404"></div>
    </div>
  );
};
