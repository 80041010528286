import { createSlice } from '@reduxjs/toolkit';
import { refundSettingsActionTypes } from '../utils';

const refundSettingsSlice = createSlice({
  name: 'refundSettings',

  initialState: {
    incentive: null,
    thankYouHeading: null,
    thankYouDesc: null,
    thankYouCardHeading: null,
    keepTheItemAmount: null,
    keepTheItemAmountDesc: null,
    refundOriginalIncentive: null,
    refundCreditIncentive: null,
    incentiveMesssage: null,
    refundOption: null,
  },

  reducers: {
    updateRefundSettingsDetails: (state, action) => {
      const { key, value } = action.payload;

      switch (key) {
        case refundSettingsActionTypes.UPDATE_INCENTIVE:
          state.incentive = value;
          break;
        case refundSettingsActionTypes.UPDATE_THANKYOU_HEADING:
          state.thankYouHeading = value;
          break;
        case refundSettingsActionTypes.UPDATE_THANKYOU_DESCRIPTION:
          state.thankYouDesc = value;
          break;
        case refundSettingsActionTypes.UPDATE_THANKYOU_CARD_HEADING:
          state.thankYouCardHeading = value;
          break;
        case refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT:
          state.keepTheItemAmount = value;
          break;
        case refundSettingsActionTypes.UPDATE_KEEP_THE_ITEM_AMOUNT_DESCRIPTION:
          state.keepTheItemAmountDesc = value;
          break;
        case refundSettingsActionTypes.UPDATE_REFUND_ORIGINAL_INCENTIVE:
          state.refundOriginalIncentive = value;
          break;
        case refundSettingsActionTypes.UPDATE_REFUND_CREDIT_INCENTIVE:
          state.refundCreditIncentive = value;
          break;
        case refundSettingsActionTypes.UPDATE_INCENTIVE_MESSAGE:
          state.incentiveMesssage = value;
          break;
        case refundSettingsActionTypes.UPDATE_REFUND_OPTION:
          state.refundOption = value;
          break;
        default:
      }
    },
  },
});

// Export the slice
export { refundSettingsSlice };

// Export the actions
export const { updateRefundSettingsDetails } = refundSettingsSlice.actions;

// Export the reducer
export default refundSettingsSlice.reducer;
