import React from "react";
import { ItemGrid } from ".";

export const Order = () => {
  return (
    <div>
      <ItemGrid />
    </div>
  );
};
