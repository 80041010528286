import { useState } from "react";
import axios from "axios";
import { useOrderContext } from "../contexts";
import { useValidateOrder } from ".";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateSetting, setProductDetails } from "../redux/slices";
import { CURRENCY_SYMBOLS } from "../currency";
import { productActionTypes, storeSettingTypes } from "../redux/utils";

export const useFetchItems = () => {
  const { orderInfo, setRecentOrderID } = useOrderContext();
  const [isLoader, setIsLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { formData, setFormData } = useValidateOrder();
  const settingState = useSelector((state) => state.storeSettings);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const orderFetch = async (value, e, orderID, CID) => {
    setFormData({ ...formData, [e?.target?.name]: value });
    try {
      setIsLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/process-return/`,
        {
          is_ajax: true,
          customer_email: formData.emailPhoneZipCode || CID,
          order_number: formData.orderNo || orderID,
          shop: settingState.storeUniqueID,
        }
      );
      const otherItemProductDetails = `${settingState.baseURL}/products`;

      dispatch(
        setProductDetails({
          key: productActionTypes.SET_OTHER_ITEM_PRODUCT_DETAILS_URL,
          value: otherItemProductDetails,
        })
      );

      if (response?.status === 200) {
        setIsLoader(false);

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_CUSTOMER_ID,
            value: response.data?.customer_id,
          })
        );

        if (response.data?.customer_email === '') {
          dispatch(
            setProductDetails({
              key: productActionTypes.SET_CUSTOMER_EMAIL,
              value: response.data?.customer_contact,
            })
          );
        } else {
          dispatch(
            setProductDetails({
              key: productActionTypes.SET_CUSTOMER_EMAIL,
              value: response.data?.customer_email,
            })
          );
        }

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_ORDER_NUMBER,
            value: response.data?.order_number,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_EXCHANGE_ITEM,
            value: response.data?.enable_exchange,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_ENABLE_INCENTIVE,
            value: response.data?.enable_incentive,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_PAYMENT_METHOD,
            value: response.data?.payment_method,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_TEST_MODE,
            value:
              response.data?.test_mode.toString().charAt(0).toUpperCase() +
              response.data?.test_mode.toString().slice(1),
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_RETURN_REASON,
            value: response?.data?.return_reasons,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_RECENT_ORDER_ID,
            value: response?.data?.recent_order_id,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_COLLECT_PRICE_DIFFERENCE_PERCENT,
            value:
              response?.exchange_settings?.collect_price_difference_percent,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_COLLECT_PRICE_DIFFERENCE_PERCENT,
            value:
              response?.exchange_settings?.collect_price_difference_percent,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_ORIGINAL_PAYMENT_METHOD_REFUND_AVAILABLE,
            value: response.data?.original_payment_method_refund_avl,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_ENABLESTORE_CREDIT_REFUND,
            value: response.data?.enable_store_credit_refund,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_ASK_ACCOUNT_INFORMATION,
            value: response.data?.ask_account_information,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_CHARGE_REVERSE_SHIPMENT_EXCHANGE,
            value: response.data?.charge_reverse_shipment_exchange,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_REVERSE_SHIPMENT_FEE,
            value: response.data?.reverse_shipment_fee,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_COLLECT_PRICE_DIFFERENCE,
            value: response.data?.exchange_settings?.collect_price_difference,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_COLLECT_PRICE_DIFFERENCE_AMOUNT,
            value:
              response.data?.exchange_settings?.collect_price_difference_amount,
          })
        );

        const Currency = CURRENCY_SYMBOLS[response.data?.currency];

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_CURRENCY,
            value: Currency,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_NEIGHBORHOOD_COMMERCE,
            value: response.data?.neighborhood_commerce,
          })
        );

        if (!response.data?.enable_refund && !response.data?.enable_incentive) {
          dispatch(
            setProductDetails({
              key: productActionTypes.SET_GET_REFUND,
              value: false,
            })
          );
        } else {
          dispatch(
            setProductDetails({
              key: productActionTypes.SET_GET_REFUND,
              value: true,
            })
          );
        }
        if (formData.orderNo === "0000") {
          dispatch(
            updateSetting({
              key: storeSettingTypes.UPDATE_IS_JOHN_DOE_TEST_MODE,
              value: true,
            })
          );
        } else {
          dispatch(
            updateSetting({
              key: storeSettingTypes.UPDATE_IS_JOHN_DOE_TEST_MODE,
              value: false,
            })
          );
        }

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_IS_SHOW_REFUND,
            value: response.data?.enable_refund,
          })
        );

        dispatch(
          setProductDetails({
            key: productActionTypes.SET_ORDER_INFO,
            value: response.data.order_data,
          })
        );

        setRecentOrderID(response.data?.recent_order_id);
        navigate("/items");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 500) {
        navigate("/internal-server-error");
      } else if (error?.response?.status === 400) {
        setIsError(true);
        setErrorMsg(error?.response?.data);
        setIsLoader(false);
      } else {
        setIsError(true);
        setErrorMsg("Something went wrong. Try again later");
        setIsLoader(false);
      }
    }
  };
  return {
    orderInfo,
    orderFetch,
    isLoader,
    isError,
    errorMsg,
    formData,
    setFormData,
  };
};
