import { htmlColors } from '.';

export const htmlColorPicker = (userColor) => {
  const filterColor = htmlColors?.filter((item) => {
    return (
      item?.name?.toLowerCase() === userColor?.toLowerCase().replace(/\s+/g, '')
    );
  });

  return userColor?.toLowerCase().replace(/\s+/g, '') ===
    filterColor[0]?.name.toLowerCase()
    ? filterColor[0]?.name.toLowerCase()
    : 'gray';
};
